import { omit } from "ramda";
import { AxiosWrapper } from "../../shared/utils/axios.wrapper";
import { IPslVendorWithMaterials, ISupplier, SupplierTabs } from "./psl";

import { pslFiltersToBackend } from "./pslQuery";
import { AxiosRequestConfig } from "axios";
import { IHistoryData } from "./pslHistory";
import { ILocalitzation, ILocalitzationWithScope, ILocalitzationWithScopeTable, NewILocalitzationTable } from "./pslLocalitzation";
import { IUserScopeFilters } from "../../shared/domains/user/user";
import { IMaterial } from "./pslMaterial";
import { IWayOfBuying, WayOfBuyingDto } from "../containers/maintenance/components/wayOfBuying/wayOfBuying";
import { IWayOfBuyingTableList } from "../containers/maintenance/components/wayOfBuyingTable/wayOfBuying";
import { IUserScopeCompany, UserService } from "src/app/shared/domains/user/user.service";
import { pslCatalogs_pslFiltersToBackend } from "src/app/pslCatalogs/misc/pslCatalogsQuery";

export class PSLService {


  static async fetchMaterialsForMaterialSearch(
    query: { filters: any, term: string, top: number, page: number, exactId: number },
    userScope: IUserScopeFilters,
    searchObj: any
  ): Promise<{ total: number, data: IMaterial[] }> {

    let finalPayload;

    if (searchObj.hasOwnProperty(undefined)) {
      finalPayload = { ...pslFiltersToBackend(query, userScope, query.filters.time, query.exactId) }
    }

    else
      finalPayload = { ...pslFiltersToBackend(query, userScope, query.filters.time, query.exactId), materialSearch: searchObj }

    const response = await AxiosWrapper.post(`/api/HackathonPsl/GetPslMaterialsV2`, finalPayload,
      {
        params: {
          skip: query.page * query.top,
          take: query.top,
          searchTerm: query.term
        }
      });
    return response.data;
  }


  static async fetchMaterials(
    query: { filters: any, term: string, top: number, page: number, exactId: number, pslSearch: any, materialSearch: any },
    userScope: IUserScopeFilters
  ): Promise<{ total: number, data: IMaterial[] }> {
    const response = await AxiosWrapper.post(`/api/HackathonPsl/GetPslMaterialsV2`,
      pslFiltersToBackend(query, userScope, query.filters.time, query.exactId, null, query.materialSearch),
      {
        params: {
          skip: query.page * query.top,
          take: query.top,
          searchTerm: query.term
        }
      });
    return response.data;
  }

  static async fetchMaterials_PslCatalogs(
    query: { filters: any, term: string, top: number, page: number, exactId: number, pslSearch: any, materialSearch: any },
    userScope: IUserScopeFilters
  ): Promise<{ total: number, data: IMaterial[] }> {
    const response = await AxiosWrapper.post(`/api/HackathonPsl/GetPslMaterialsV2`,
      pslCatalogs_pslFiltersToBackend(query, userScope, query.filters.time, query.exactId, null, query.materialSearch),
      {
        params: {
          skip: query.page * query.top,
          take: query.top,
          searchTerm: query.term
        }
      });
    return response.data;
  }

  static async fetchMaterialsForRc(vendorList: { title: string, id: string }[], compCode: string, page: number, top: number, searchTerm: string): Promise<{ total: number, data: IMaterial[] }> {
    const compCodes = await UserService.fetchUserScope(true) as IUserScopeCompany[];
    console.log(compCodes);
    const compCodeId = compCodes.filter(item => item.code === compCode)[0].id;
    console.log(compCodeId);

    const payload = {
      spendCatL1s: [],
      spendCatL2s: [],
      spendCatL3s: [],
      companies: [{ id: `${compCodeId}`, title: "" }],
      markets: [],
      statuses: [],
      complianceStatuses: [],
      suppliers: vendorList,
      materials: [],
      businessUnits: [],
      strategicBuyers: [],
      pslStatuses: [{ "id": 1 }],
      supplierDiversity: [],
      wayOfBuying: [],
      scope: [{ title: "At Market", id: "At Market" }],
      time: "active",
      exactId: null,
      taskKind: null,
      createdByUserId: null,
      fromSpend: "",
      toSpend: "",
      childrenOf: "",
      currencySpend: "",
      plants: "",
      orderBy: null,
      pslCreationAndValidityDate: null,
      zones: [],
      pslIds: [],
      materialSearch: searchTerm ? { all: searchTerm } : null,
      pslSearch: null,
      showObsolete: false,
      FavoriteSwitchOn: false,
      supplierDescription: []
    };
    const response = await AxiosWrapper.post(`/api/HackathonPsl/GetPslMaterialsV2`, payload, {
      params: {
        skip: page * top,
        take: top,
      }
    });
    const finalResult = response.data;
    finalResult.data = response.data?.data?.map(material => {
      return {
        pslId: material?.pslId,
        vendorNo: material?.supplierCode,
        vendorDescription: material?.supplierName,
        PrMaterial: material?.materialNumber?.padStart(18, '0'),
        PrItemTxt: material?.shortDescription,
        status: material?.statusName,
        PrPriceRaw: material?.unitPrice,
        PrNetPriceRaw: material?.unitPrice,
        leadTimes: material?.supplierLeadTime,
        buyer: material?.strategicBuyerEmailAddress,
        PrQuantityUom: material?.unitOfMeasure,
        PrCurrency: material?.currencyCode,
        PrNetPriceUnit: material?.pricePer,
        materialId: material?.materialId
      }
    });
    return finalResult;
  }

  static async fetchPslForPSLSearch(
    query: { filters: any, term: string, top: number, page: number, exactId: number, childrenOf: number },
    userScope: IUserScopeFilters,
    searchObj: any
  ): Promise<{ total: number, data: ISupplier[] }> {

    let finalPayload;

    // to check if searchObj has undefined property ---- it means we are clearing the category search.

    if (searchObj.hasOwnProperty(undefined)) {
      finalPayload = { ...pslFiltersToBackend(query, userScope, query.filters.time, query.exactId) }
    }

    else finalPayload = { ...pslFiltersToBackend(query, userScope, query.filters.time, query.exactId), pslSearch: searchObj };

    const response = await AxiosWrapper.post(`/api/HackathonPsl/GetPslEntriesV2`,
      finalPayload,
      {
        params: {
          skip: query.page * query.top,
          take: query.top,
          searchTerm: query.term
        }
      });
    return response.data;
  }


  static async fetchPsl(
    query: { filters: any, term: string, top: number, page: number, exactId: number, childrenOf: number, pslSearch: any },
    userScope: IUserScopeFilters,
    isPslRefactorAndCommonFiltersToggleActive,
    queryCopy
  ): Promise<{ total: number, data: ISupplier[] }> {

    let finalQuery = query;

    if (isPslRefactorAndCommonFiltersToggleActive) {
      finalQuery = queryCopy
    }

    const response = await AxiosWrapper.post(`/api/HackathonPsl/GetPslEntriesV2`,
      // pslFiltersToBackend(query, userScope, query.filters.time, query.exactId, query.pslSearch)
      pslFiltersToBackend(finalQuery, userScope, finalQuery.filters.time, finalQuery.exactId, finalQuery.pslSearch)
      ,
      {
        params: {
          skip: query.page * query.top,
          take: query.top,
          searchTerm: query.term
        }
      });
    return response.data;
  }
  static async save(psl: ISupplier, categoryIds: number[]): Promise<ISupplier> {
    const response = await AxiosWrapper.post(`/api/HackathonPsl/AddPslEntry`, {
      vendor: omit(['id'], psl),
      categoryIds,
    });
    return response.data;
  }
  static async fetchSinglePsl(id: number): Promise<any> {
    const response = await AxiosWrapper.get(`/api/HackathonPsl/GetPslEntry?id=${id}`);
    return response.data;
  }
  static async fetchSingleMaterial(id: number): Promise<any> {
    const response = await AxiosWrapper.get(`/api/HackathonPsl/GetPslMaterial?id=${id}`);
    return response.data;
  }
  static async fetchMaterialsByPslId(pslId: number, showDeleted = false): Promise<any> {
    const response = await AxiosWrapper.get(`/api/HackathonPsl/GetMaterialsForPslEntry?id=${pslId}&includeDeleted=${showDeleted}`);
    return response.data;
  }

  static async saveMaterial(material: IMaterial, pslId: number): Promise<any> {
    const response = await AxiosWrapper.post(`/api/HackathonPsl/AddMaterialForPslEntry`, omit(['materialId'], {
      ...material,
      pslId: pslId
    })
    );
    return response.data;
  }
  static async saveMaterialsFromFile(formData: any, pslId: number): Promise<any> {
    const response = await AxiosWrapper.post(`/api/HackathonPsl/AddMaterialsForPslEntryFromExcel?pslId=${pslId}`,
      formData);
    return response.data;
  }
  static async update(psl: ISupplier): Promise<ISupplier> {
    const response = await AxiosWrapper.post(`/api/HackathonPsl/UpdatePslEntry`, psl);
    return response.data;
  }
  static async localizeWithMaterials(psl: IPslVendorWithMaterials): Promise<ISupplier> {
    const response = await AxiosWrapper.post(`/api/HackathonPsl/LocalizePslWithMaterials`, psl);
    return response.data;
  }
  static async reactivate(reactivatePsl: { pslId?: number, validTo?: string }): Promise<ISupplier> {
    const response = await AxiosWrapper.post(`/api/HackathonPsl/ReactivatePslEntry`, reactivatePsl);
    return response.data;
  }
  static async reactivateV2(psl: IPslVendorWithMaterials): Promise<ISupplier> {
    const response = await AxiosWrapper.post(`/api/HackathonPsl/ReactivatePslEntryV2`, psl);
    return response.data;
  }
  static async savePslApproversFilter(filters): Promise<any> {
    const response = await AxiosWrapper.post(`/api/Settings/SaveFilters/Approvers`, filters);
    return response.data;
  }
  static async updateMaterialsInBulk(material: IMaterial[]): Promise<ISupplier> {
    const response = await AxiosWrapper.post(`/api/HackathonPsl/UpdateMaterialsForPslEntryBulk`, material);
    return response.data;
  }

  static async import(formData): Promise<any> {
    const options: AxiosRequestConfig = { timeout: 15000 }
    const response = await AxiosWrapper.post(`/api/Psl/PostPslListFromExcel`, formData, options);
    return response.data;
  }

  static async exportTemplate(list: SupplierTabs): Promise<any> {
    const templateLink = list === SupplierTabs.PSL ?
      '/api/Psl/GetPslListExcelTemplate' : '/api/HackathonPsl/GetMaterialListExcelTemplate';
    const response = await AxiosWrapper.get(templateLink, {
      responseType: 'blob'
    });
    return response.data;
  }

  static async exportData(query: { filters: any, term: string, top: number, page: number, exactId: number, createdByUserId: number, pslSearch: any, materialSearch: any },
    userScope: IUserScopeFilters, isPslRefactorAndCommonFiltersToggleActive: boolean,
    queryCopy: any): Promise<any> {

    let finalQuery = query;

    if (isPslRefactorAndCommonFiltersToggleActive) {
      finalQuery = queryCopy
    }

    const response = await AxiosWrapper.post('/api/HackathonPsl/GetPslMaterialsAsExcelV2',
      // pslFiltersToBackend(query, userScope, query.filters.time, query.exactId, query.pslSearch, query.materialSearch), 
      pslFiltersToBackend(finalQuery, userScope, finalQuery.filters.time, finalQuery.exactId, null, finalQuery.pslSearch || finalQuery.materialSearch),
      //GetPslMaterialsAsExcelV2 expects materialSearch only and not pslSearch, as export excel is based on material, hence the above change

      {
        responseType: 'blob',
        params: { searchTerm: query.term }
      });
    return response.data;
  }

  static async exportDataByEmail(query: { filters: any, term: string, top: number, page: number, exactId: number, createdByUserId: number, pslSearch: any, materialSearch: any },
    userScope: IUserScopeFilters, isPslRefactorAndCommonFiltersToggleActive: boolean,
    queryCopy: any): Promise<any> {

    let finalQuery = query;

    if (isPslRefactorAndCommonFiltersToggleActive) {
      finalQuery = queryCopy
    }

    const response = await AxiosWrapper.post('/api/HackathonPsl/GetPslMaterialsAsExcelV2ByEmail',
      // pslFiltersToBackend(query, userScope, query.filters.time, query.exactId, query.pslSearch, query.materialSearch),
      pslFiltersToBackend(finalQuery, userScope, finalQuery.filters.time, finalQuery.exactId, finalQuery.pslSearch, finalQuery.materialSearch),
      {
        params: { searchTerm: query.term }
      });
    return response.data;
  }

  static async checkIfDuplicate(mat: IMaterial): Promise<{ id: string, duplicate: boolean }> {
    const response = await AxiosWrapper.post(`/api/HackathonPsl/CheckExistingPsl`, omit(['materialId'], mat));
    return { id: mat.materialId, duplicate: (response.data !== 0 && response.data !== mat.materialId) };
  }
  static async approvePsl(id: number): Promise<any> {
    const response = await AxiosWrapper.post(`/api/HackathonPsl/ApprovePsl?pslId=${id}`, {});
    return response.data;
  }
  static async rejectPsl(ids: number[], reason: string): Promise<any> {
    const query = ids.map(id => "pslIds=" + id).join("&")
    const response = await AxiosWrapper.post(`/api/HackathonPsl/RejectPsl?${query}&comment=${reason}`, {});
    return response.data;
  }
  static async deletePsl(id: number): Promise<any> {
    const response = await AxiosWrapper.post(`/api/HackathonPsl/DeletePsl?pslId=${id}`, {});
    return response.data;
  }
  static async deleteMaterialPsl(id: number): Promise<any> {
    const response = await AxiosWrapper.post(`/api/HackathonPsl/DeletePslMaterial?materialId=${id}`, {});
    return response.data;
  }
  static async fetchCategoryTree(): Promise<any> {
    const response = await AxiosWrapper.get('/api/PslSpendCat/GetPslSpendCat');
    return response.data;
  }
  static async fetchPSLHistory(pslId: number): Promise<IHistoryData> {
    const response = await AxiosWrapper.get(`/api/HackathonPsl/GetPslHistory?pslId=${pslId}`);
    return response.data;
  }
  static async fetchMaterialHistory(materialId: number): Promise<IHistoryData> {
    const response = await AxiosWrapper.get(`/api/HackathonPsl/GetPslHistory?materialId=${materialId}`);
    return response.data;
  }

  static async newFetchLocalitzationTasksForTable(skip: number = 0, take: number = 10, queryParms: string): Promise<NewILocalitzationTable> {
    let queryParams = `?skip=${skip}&take=${take}${queryParms === "" ? "" : "&" + queryParms}`;
    const response = await AxiosWrapper.get(`/api/Localizers` + queryParams);
    return response.data;
  }

  static async fetchLocalitzationTasks(query: string): Promise<ILocalitzation[]> {
    const response = await AxiosWrapper.get(`/api/Localizers` + query);
    return response.data;
  }

  static async saveLocalizer(query: ILocalitzation): Promise<ILocalitzation> {
    const response = await AxiosWrapper.post(`/api/Localizers`, query);
    return response.data;
  }

  static async updateLocalizer(query: ILocalitzation): Promise<ILocalitzation> {
    const response = await AxiosWrapper.put(`/api/Localizers`, query);
    return response.data;
  }

  static async deleteLocalizer(id: string): Promise<ILocalitzation> {
    const response = await AxiosWrapper.delete(`/api/Localizers/` + id);
    return response.data;
  }

  static async fetchApprovers(scope: number, queryParams: string): Promise<ILocalitzationWithScope[]> {
    const response = await AxiosWrapper.get(`/api/Approvers?scope=` + scope + queryParams);
    return response.data;
  }

  static async newFetchApprovers(skip: number = 0, take: number = 10, scope: number, queryParms: string): Promise<NewILocalitzationTable> {
    let queryParams = `?skip=${skip}&take=${take}&scope=${scope}${queryParms === "" ? "" : "&" + queryParms}`;
    const response = await AxiosWrapper.get(`/api/Approvers${queryParams}`);
    return response.data;
  }

  static async saveApprover(query: ILocalitzationWithScope): Promise<ILocalitzation> {
    const response = await AxiosWrapper.post(`/api/Approvers`, query);
    return response.data;
  }

  static async updateApprover(query: ILocalitzationWithScope | ILocalitzationWithScopeTable): Promise<ILocalitzation> {
    const response = await AxiosWrapper.put(`/api/Approvers`, query);
    return response.data;
  }

  static async deleteApprover(id: string): Promise<ILocalitzation> {
    const response = await AxiosWrapper.delete(`/api/Approvers/` + id);
    return response.data;
  }

  static async fetchWayOfBuying(queryParams: string): Promise<IWayOfBuying[]> {
    const response = await AxiosWrapper.get(`/api/WayOfBuying` + queryParams);
    return response.data;
  }

  static async fetchNewWayOfBuying(skip: number = 0, take: number = 10, queryParms: string): Promise<IWayOfBuyingTableList> {
    let queryParams = `?skip=${skip}&take=${take}${queryParms === "" ? "" : "&" + queryParms}`;
    const response = await AxiosWrapper.get(`/api/WayOfBuying` + queryParams);
    return response.data;
  }

  static async fetchDefaultWayOfBuying(spendCatL3s: number, markets: number[], companies: number[]): Promise<IWayOfBuying> {
    const response = await AxiosWrapper.get(`/api/WayOfBuying/default`,
      {
        params: {
          markets: markets?.join(","),
          companies: companies?.join(","),
          spendCatL3s
        }
      });
    return response.data;
  }

  static async saveWayOfBuying(query: WayOfBuyingDto): Promise<IWayOfBuying> {
    const response = await AxiosWrapper.post(`/api/WayOfBuying`, query);
    return response.data;
  }

  static async updateWayOfBuying(query: WayOfBuyingDto): Promise<IWayOfBuying> {
    const response = await AxiosWrapper.put(`/api/WayOfBuying`, query);
    return response.data;
  }

  static async deleteWayOfBuying(id: string): Promise<IWayOfBuying> {
    const response = await AxiosWrapper.delete(`/api/WayOfBuying/` + id);
    return response.data;
  }
  static async syncCompanyCodes(body: string[]): Promise<any> {
    const response = await AxiosWrapper.post(`/api/AribaSync/SyncCompanyCodes`, body);
    return response.data;
  }

  static async syncTestMarkets(body: string[]): Promise<any> {
    const response = await AxiosWrapper.post(`/api/AribaSync/SyncTestMarkets`, body);
    return response.data;
  }

  // Create PSL vendor with meterials API call 
  static async createPslVendorWithMaterials(psl: IPslVendorWithMaterials): Promise<ISupplier> {
    const response = await AxiosWrapper.post(`/api/HackathonPsl/AddPSLVendorsWithMaterials`, psl);
    return response.data;
  }

  // Update PSL vendor with meterials API call 
  static async updatePslVendorWithMaterials(psl: IPslVendorWithMaterials): Promise<any> {
    const response = await AxiosWrapper.post(`/api/HackathonPsl/UpdatePSLVendorsWithMaterials`, psl);
    return response.data;
  }

  static async updateFavouriteToggle(pslId: number): Promise<boolean> {
    const response = await AxiosWrapper.post(`/api/HackathonPsl/AddOrRemovePslFavorites` + `?pslid=${pslId}`);
    return response.data;
  }

  static async getVendorInfo(supplierCode: string | number): Promise<boolean> {
    const response = await AxiosWrapper.get(`/api/HackathonPsl/GetAmdrSupplierEntry` + `?supplierCode=${supplierCode}`);
    return response.data;
  }

  static async fetchPslUserEmails(email: string): Promise<{ value: string, label: string }[]> {
    if (email.length < 4) return Promise.resolve([]);

    const response = await AxiosWrapper.get('/api/psl/StrategicBuyerEmails', { params: { searchTerm: email } });
    return response.data.map(x => ({ value: x, label: x }));
  }

  static async savePslCatalogsFilters(filters: any): Promise<any> {
    const response = await AxiosWrapper.post(`/api/Settings/SaveFilters/PslSpenderView`, filters);
    return response.data;
  }
}
