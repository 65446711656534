import React from "react";
import { DataWithPopover } from "src/app/nbsCockpit/components/nbsCockpitDataWithPopover";

export function truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
}

export const hoverDetails = (item: any, hoverItem: any) => {
    return (
        <DataWithPopover
            item={item}
            hoverItem={hoverItem}
        />
    )
}
