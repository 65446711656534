import { useMutation, useQuery } from "react-query";
import { DocflowChatService } from "src/app/docFlows/domains/docflowChat/docflowChat.service";
import { MasterService } from "src/app/shared/domains/master/master.service";
import { NbsService } from "../services/nbs.service";
import { Invoice } from "src/app/docFlows/invoices/domains/invoices/Invoice";
import { PRPOItemService } from "src/app/docFlows/domains/prpo/item.service";
import { NbsCockpitTabs } from "../helpers/constants";
import { assignmentData } from "../components/nbsBlockedParkedAssignmentPopUp.component";
import { DownloadCompletedAction } from "src/app/shared/domains/core/actions";
import { useDispatch, useSelector } from "react-redux";
import { ExportFeedbackListDataEmailError } from "src/app/admin/actions/feedback.actions";

export type EmailBody = {
    CompCode: string;
    CompCodeTxt: string;
    PrCreationDate: string;
    SapSystemAlias: string;
    Approved: boolean;
    PrModifier: string;
    ApproverName: string;
    PurchaseReqNo: string;
    Comment: string;
}

interface IRenameFavFilters {
    oldFilterName: string;
    newFilterName: string;
}

export const useFetchDocumentList = (systemAlias: string, Instid: string, Typeid: string, isItEnabled: boolean) => {
    return useQuery("attachmentList" + Instid + systemAlias,
        () => MasterService.fetchInvoices(Instid, Typeid, systemAlias), { enabled: isItEnabled, staleTime: Infinity, retry: 2 });
};

export const useFetchDocument = (Instid: string, TypeId: string, AttaId: string, systemAlias: string, isItEnabled: boolean) => {
    return useQuery("attachment" + AttaId + systemAlias + TypeId + Instid,
        () => MasterService.fetchInvoiceData(Instid, TypeId, AttaId, systemAlias), { enabled: isItEnabled, staleTime: Infinity, retry: 2 });
};

export const useFetchNbsEasyRequestdata = (query: any, zone: string, isItEasyRequestTab: boolean) => {
    return useQuery("nbsEasyRequestData" + query?.queryKey + zone,
        () => NbsService.fetchEasyRequestData(query, zone), { enabled: isItEasyRequestTab, staleTime: Infinity, retry: 2 });
};

export const useFetchNbsBlockedParkeddata = (query: any, zone: string, isItBlockedParkedTab: boolean) => {
    return useQuery("nbsBlockedParkedData" + query?.queryKey + zone + query?.subTab,
        () => NbsService.fetchBlockedparkedData(query, zone, query.subTab), { enabled: isItBlockedParkedTab, staleTime: Infinity, retry: 2 });
};

export const useFetchNbsItemData = (systemAlias: string, prNo: string) => {
    return useQuery("nbsItemData" + prNo + systemAlias,
        () => NbsService.fetchNbsItemData(systemAlias, prNo), { staleTime: Infinity, retry: 2 });
};

export const useFetchNbsTrackerdata = (query: any, zone: string, isItTrackerTab: boolean) => {
    return useQuery("nbsTrackerData" + query?.queryKey + zone,
        () => NbsService.getTrackerData(query, zone), { enabled: isItTrackerTab, staleTime: Infinity, retry: 2 });
};

export const useFetchNbsBlockededItemData = (inVoice: Invoice) => {
    return useQuery("nbsBlockedParkedItemData" + inVoice.WfId + inVoice.SystemAlias,
        () => PRPOItemService.fetchInvoiceItemSet(inVoice), { staleTime: Infinity, retry: 2 });
};

export const useFetchNbsTaxCode = (compCode: string, systemAlias: string, isItEnabled: boolean) => {
    return useQuery("taxCodes" + compCode + systemAlias, () => MasterService.fetchTaxCode(compCode, systemAlias), { enabled: isItEnabled, staleTime: Infinity, retry: 2 })
}

export const useAppRejNbsCockpit = (onClose: Function) => {
    return useMutation(({ nbsItemData, systemAlias }: { nbsItemData: any, systemAlias: string }) => {
        return NbsService.appRejNbsCockpit(nbsItemData, systemAlias)
    },
        {
            onSettled: () => {
                onClose()
            }
        })
}

export const useNbsSendEmail = () => useMutation(({ body, systemAlias }: { body: EmailBody, systemAlias: string }) => NbsService.nbsSendEmail(body, systemAlias))

export const useNbsTagRejectMsg = () => useMutation((body: any) => DocflowChatService.newLatestSendMessage(body));

export const useCancelRemainder = () => useMutation((invoiceNumber: string) => NbsService.cancelRemainder(invoiceNumber));

export const useReactivateRemainder = () => useMutation((invoiceNumber: string) => NbsService.reactivateRemainder(invoiceNumber));

export const useSaveEasyRequestFilters = () => {
    return useMutation((filters: any) => NbsService.saveEasyRequestFilters(filters))
}
export const useSaveBlockedParkedFilters = () => {
    return useMutation((filters: any) => NbsService.saveBlockedParkedFilters(filters))
}
export const useSaveTrackerFilters = () => {
    return useMutation((filters: any) => NbsService.saveTrakerFilters(filters))
}
export const usePostTrackerData = () => {
    return useMutation((payload: any) => NbsService.postTrackerData(payload))
}

export const useGetFavFilters = (tab: string) => {
    return useQuery("favFilters" + tab, () => tab === NbsCockpitTabs.EASY_REQUEST ? NbsService.getFavFilters() : NbsService.getBlockedParkedFavFilters(),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            staleTime: 24 * 60 * 60 * 1000,
            cacheTime: 24 * 60 * 60 * 1000
        })
}

export const useAddFavFilters = (tab: string) => {
    return useMutation((filters: any) => tab === NbsCockpitTabs.EASY_REQUEST ? NbsService.saveFavFilters(filters) : NbsService.saveBlockedParkedFavFilters(filters))
}

export const useDeleteFavFilters = (tab: string) => {
    return useMutation((filterName: string) => tab === NbsCockpitTabs.EASY_REQUEST ? NbsService.deleteFavFilters(filterName) : NbsService.deleteBlockedParkedFavFilters(filterName))
}

export const useRenameFavFilters = (tab: string) => {
    return useMutation((oldNameAndNewName: IRenameFavFilters) => tab === NbsCockpitTabs.EASY_REQUEST ? NbsService.renameFavFilters(oldNameAndNewName) : NbsService.renameBlockedParkedFavFilters(oldNameAndNewName))
}

export const useAssignBlockedParkedData = (onClose) => {

    return useMutation((payload: assignmentData[]) => NbsService.assignBlockedParkedData(payload),
        {
            onSuccess: () => {
                onClose()
            }
        })
};

export const useExportTrackerData = (onSuccess, onError) => {

    return useMutation((payload: any) => NbsService.exportTrackerData(payload, payload.zone),
        {
            onSuccess: () => {
                onSuccess("You will receive an email with Tracker data as an attachment")
            },
            onError: (error: any) => {
                onError(error?.message)
            }
        })
};


export const useFetchGoodsReciptsData = (poNo: string, poItemNo: string, systemAlias: string, isItEnabled: boolean) => {
    return useQuery("goodsReceipts" + poNo + poItemNo + systemAlias, () => PRPOItemService.fetchGoodsReciptsDetails({ poNo, poItemNo, systemAlias }), {
        enabled: isItEnabled,
        staleTime: Infinity,
        // cacheTime: 5000,
        retry: 2
    }
    )
}

export const useFetchInvoicesReceiptsData = (poNo: string, poItemNo: string, systemAlias: string, isItEnabled: boolean) => {
    return useQuery("invoiceReceipts" + poNo + poItemNo + systemAlias, () => PRPOItemService.fetchInvoiceReciptsDetails({ poNo, poItemNo, systemAlias }), {
        enabled: isItEnabled,
        staleTime: Infinity,
        retry: 2
    }
    )
}

export const useCreateOrUpdateNbsMaintenanceTemplate = (closelMaintenancePopUpModel, setSuccessMessage, setErrorMessage, mode) => {
    return useMutation((templateData: any) => NbsService.createOrUpdateNbsMaintenanceTemplate(templateData),
        {
            onSuccess: () => {
                closelMaintenancePopUpModel()
                if (mode === 'create') {
                    setSuccessMessage('Template has been succesfully created')
                } else {
                    setSuccessMessage('Template has been succesfully updated')
                }
            },
            onError: (e) => {
                setErrorMessage(e)
            }
        })
}

export const useDeleteNbsMaintenanceTemplates = (closeOrCancelDeletePopUpModel, setSuccessMessage, setErrorMessage) => {
    return useMutation((templateData: any) => NbsService.deleteNbsMaintenanceTemplates(templateData),
        {
            onSuccess: () => {
                closeOrCancelDeletePopUpModel()
                setSuccessMessage('Template has been succesfully deleted')
            },
            onError: (e) => {
                setErrorMessage(e)
            }
        })
}

export const useFetchNbsMaintenanceTemplatesData = (query: any) => {
    return useQuery("nbsTemplatesData" + JSON.stringify(query), () => NbsService.fetchNbsMaintenanceTemplatesData(query), { staleTime: Infinity, retry: 2 });
};

export const useFetchNbsMaintenceReasonsCodes = (systemAlias: string, isItEnabled?: boolean) => {
    return useQuery("reasonCodes" + systemAlias, () => MasterService.fetchReasonCodes(systemAlias), { enabled: isItEnabled, staleTime: Infinity, retry: 2 })
};

export const useSaveTemplateFilters = () => {
    return useMutation((filters: any) => NbsService.saveTemplateFilters(filters))
}

export const useExportNbsMaintenanceData = (setSuccessMessage, setErrorMessage) => {
    const dispatch = useDispatch();
    return useMutation((postData: any) => NbsService.exportNbsTemplateData(postData), {
        onSuccess: (data) => {
            try {
                if (data instanceof Blob) {
                    new DownloadCompletedAction('NbsTemplatesData.xlsx', data)
                    setSuccessMessage('Excel file has been downloaded');
                }
            } catch (e) {
                dispatch(new ExportFeedbackListDataEmailError(e.toString()))
                setErrorMessage(e)
            }
        },
        onError: (e) => {
            setErrorMessage(e)
        }
    })
}

export const useNbsMaintenanceImport = (onSuccessFunc, onErrorFunc) => {

    return useMutation((formData: any) => NbsService.nbsCockpitMaintenanceImport(formData),
        {
            onSuccess: () => {
                onSuccessFunc()
            },
            onError: (error: any) => {
                onErrorFunc(error)
            }
        })
};

export const useFetchLineManager = (email: string, isItEnabled?: boolean) => {
    return useQuery("lineManager" + email, () => MasterService.getLineManager(email), { enabled: isItEnabled, staleTime: Infinity, retry: 2 })
};