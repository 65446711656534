import * as R from "ramda";
import {Dictionary} from "ramda";
import {newQuery, Query, setSelectedFiltersBykey} from "../../shared/queryable/query";
import {FilterCategoryView, newTextFilterCategory} from "../../shared/domains/query/filter";
import {IPslFilters, IUserScopeFilters} from "../../shared/domains/user/user";
import {SupplierFilterTabs} from "./psl";
import {capitalize} from "../../shared/utils/global";
import moment from "moment";
import { checkPslExactId } from "../containers/list/preferredSuppliers.page";
import { Features } from "src/app/shared/domains/core/pataflag";
import { PataflagService } from "src/app/shared/services/pataflag.service";

export enum PslFilterKeys {
  SPEND_CAT_1 = 'SpendCat1Code',
  SPEND_CAT_2 = 'SpendCat2Code',
  SPEND_CAT_3 = 'SpendCat3Code',
  COMP_CODE = 'CompanyCode',
  MARKETS = 'Markets',
  STATUS = 'StatusId',
  SUPPLIER = 'SupplierId',
  MATERIAL_NUMBER = 'MaterialNumber',
  BUSINESS_UNITS = 'BusinessUnits',
  FROM_SPEND = 'fromSpend',
  TO_SPEND = 'toSpend',
  CURRENCY_SPEND = 'currencySpend',
  BUYERS = 'StrategicBuyers',
  PLANT = "plants",
  PARENT_PSL = "childrenOf",
  SUPPLIER_DIVERISTY = "SupplierDiversity",
  SCOPE = "Scope",
  PSL_CREATION_AND_VALIDITY_DATE = "creationAndValidityDate",
  LOCATION = "location",
  ZONE = 'zones',
  PSLIDS = 'pslIds',
  WAY_OF_BUYING = 'WayOfBuying',
  SHOW_OBSOLETE = 'ShowObsolete',
  SUPPLIER_DISCRIPTION = 'supplierDescription',
}
  // add default value of any new filter added below, this is for reset button in filter page
export const initialPslFiltersvalue = {
  "spendCatL1s": [],
  "spendCatL2s": [],
  "spendCatL3s": [],
  "companies": [],
  "markets": [],
  "zones":[],
  "suppliers": [],
  "materials": [],
  "statuses": [],
  "businessUnits": [],
  "strategicBuyers": [],
  "supplierDiversity": [],
  "scope" : null,
  "pslCreationAndValidityDate" : null
}

export const materialSearchOptions = [
  {value: 'all', label : "All"},
  {value: 'material', label : "Material"},
  {value: 'materialDescription', label: 'Material Description'},
  {value: 'supplier', label: 'Supplier'},
  {value: 'supplierPartId', label: 'Supplier Part iD'},
  {value: 'shortDescription', label: 'Short Description'},
  {value: 'scopeOfApplication', label: 'Scope of Application'},
  {value: 'keywords', label: 'Keywords'},
  {value: 'manufacturerName', label: 'Manufacturer Name'},
  {value: 'manufacturerPartNo', label: 'Manufacturer Part Number'}
];

export const pslSearchOptions = [
  {value: 'all', label : "All"},
  {value: 'material', label : "Material"},
  {value: 'materialDescription', label: 'Material Description'},
  {value: 'supplier', label: 'Supplier'},
  {value: 'shortDescription', label: 'Short Description'},
  {value: 'scopeOfApplication', label: 'Scope of Application'},
  {value: 'keywords', label: 'Keywords'},
];

export const materialSearchNotAllOptions = [
  {value: 'material', label : "Material"},
  {value: 'materialDescription', label: 'Material Description'},
  {value: 'supplier', label: 'Supplier'},
  {value: 'supplierPartId', label: 'Supplier Part iD'},
  {value: 'shortDescription', label: 'Short Description'},
  {value: 'scopeOfApplication', label: 'Scope of Application'},
  {value: 'keywords', label: 'Keywords'},
  {value: 'manufacturerName', label: 'Manufacturer Name'},
  {value: 'manufacturerPartNo', label: 'Manufacturer Part Number'}
];

export const newMaterialSearchOptions = [
  {value: 'all', label : "All"},
  {value: 'material', label : "Material"},
  {value: 'materialDescription', label: 'Material Description'},
  {value: 'plant', label: 'Plant'},
  {value: 'supplier', label: 'Supplier'},
  {value: 'supplierPartId', label: 'Supplier Part iD'},
  {value: 'shortDescription', label: 'Short Description'},
  {value: 'scopeOfApplication', label: 'Scope of Application'},
  {value: 'keywords', label: 'Keywords'},
  {value: 'manufacturerName', label: 'Manufacturer Name'},
  {value: 'manufacturerPartNo', label: 'Manufacturer Part Number'}
];

export const newMaterialSearchNotAllOptions = [
  {value: 'material', label : "Material"},
  {value: 'materialDescription', label: 'Material Description'},
  {value: 'plant', label: 'Plant'},
  {value: 'supplier', label: 'Supplier'},
  {value: 'supplierPartId', label: 'Supplier Part iD'},
  {value: 'shortDescription', label: 'Short Description'},
  {value: 'scopeOfApplication', label: 'Scope of Application'},
  {value: 'keywords', label: 'Keywords'},
  {value: 'manufacturerName', label: 'Manufacturer Name'},
  {value: 'manufacturerPartNo', label: 'Manufacturer Part Number'}
];

export const pslSearchNotAllOptions = [
    {value: 'material', label : "Material"},
    {value: 'materialDescription', label: 'Material Description'},
    {value: 'supplier', label: 'Supplier'},
    {value: 'shortDescription', label: 'Short Description'},
    {value: 'scopeOfApplication', label: 'Scope of Application'},
    {value: 'keywords', label: 'Keywords'},
];




export const PslFilters = [
  {key: PslFilterKeys.SPEND_CAT_1, label: 'Spend Category L1', showAdd: true},
  {key: PslFilterKeys.SPEND_CAT_2, label: 'Spend Category L2', showAdd: true},
  {key: PslFilterKeys.SPEND_CAT_3, label: 'Spend Category L3', showAdd: true},
  {key: PslFilterKeys.LOCATION, label: 'Location', showAdd: false},
  {key: PslFilterKeys.COMP_CODE, label: 'Company code', showAdd: true},
  {key: PslFilterKeys.PLANT, label: 'Plant', showAdd: true},
  {key: PslFilterKeys.MARKETS, label: 'Markets', showAdd: true},
  {key: PslFilterKeys.STATUS, label: 'Status', showAdd: true},
  {key: PslFilterKeys.SUPPLIER, label: 'Supplier', showAdd: true},
  {key: PslFilterKeys.MATERIAL_NUMBER, label: 'Material Number', showAdd: true},
  {key: PslFilterKeys.SCOPE, label: 'Scope', showAdd: true},
  {key: PslFilterKeys.PSLIDS, label: 'PSL IDs', showAdd: true},
  {key: PslFilterKeys.BUSINESS_UNITS, label: 'Business Unit', showAdd: true},
  {key: PslFilterKeys.FROM_SPEND, label: 'From', showAdd: false},
  {key: PslFilterKeys.TO_SPEND, label: 'To', showAdd: false},
  {key: PslFilterKeys.PARENT_PSL, label: 'Parent PSL', showAdd: false},
  {key: PslFilterKeys.CURRENCY_SPEND, label: 'Currency', showAdd: false},
  {key: PslFilterKeys.PSL_CREATION_AND_VALIDITY_DATE, label: 'PSL Creation and Validity Date', showAdd: true },
  {key: PslFilterKeys.BUYERS, label: 'Strategic Buyer', showAdd: true},
  {key: PslFilterKeys.WAY_OF_BUYING, label: 'Way Of Buying', showAdd: true},
  {key: PslFilterKeys.SUPPLIER_DIVERISTY, label: 'Supplier Diversity', showAdd: true},
  {key: PslFilterKeys.ZONE, label: 'Zones', showAdd: true},
  {key: PslFilterKeys.SHOW_OBSOLETE, label: 'Display Obsolete PSLs', showAdd: true},
];

const initial = PslFilters.map(x => newTextFilterCategory(x.key, x.label));
const initialDictionary = R.indexBy(R.prop('key'), initial);

export function newPslQuery(defaultFilters?: Partial<IPslFilters>, term?: string): Query {
  let initialFilter: Dictionary<FilterCategoryView> = initialDictionary;
  console.log(defaultFilters);
  if (defaultFilters) {
    initialFilter = R.assocPath([PslFilterKeys.PSLIDS, 'selected'], [], initialFilter);
    initialFilter = setSelectedFiltersBykey(PslFilterKeys.SPEND_CAT_1, defaultFilters.spendCatL1s, initialFilter);
    initialFilter = setSelectedFiltersBykey(PslFilterKeys.SPEND_CAT_2, defaultFilters.spendCatL2s, initialFilter);
    initialFilter = setSelectedFiltersBykey(PslFilterKeys.SPEND_CAT_3, defaultFilters.spendCatL3s, initialFilter);
    initialFilter = setSelectedFiltersBykey(PslFilterKeys.PLANT, defaultFilters.plants, initialFilter);
    initialFilter = setSelectedFiltersBykey(PslFilterKeys.COMP_CODE, defaultFilters.companies, initialFilter);
    initialFilter = setSelectedFiltersBykey(PslFilterKeys.STATUS, defaultFilters.statuses, initialFilter);
    initialFilter = setSelectedFiltersBykey(PslFilterKeys.SUPPLIER, defaultFilters.suppliers, initialFilter);
    initialFilter = setSelectedFiltersBykey(PslFilterKeys.MATERIAL_NUMBER, defaultFilters.materials, initialFilter);
    initialFilter = setSelectedFiltersBykey(PslFilterKeys.BUSINESS_UNITS, defaultFilters.businessUnits, initialFilter);
    initialFilter = setSelectedFiltersBykey(PslFilterKeys.BUYERS, defaultFilters.strategicBuyers, initialFilter);
    initialFilter = setSelectedFiltersBykey(PslFilterKeys.MARKETS, defaultFilters.markets, initialFilter);
    initialFilter = setSelectedFiltersBykey(PslFilterKeys.SUPPLIER_DIVERISTY, defaultFilters.supplierDiversity, initialFilter);
    initialFilter = setSelectedFiltersBykey(PslFilterKeys.SCOPE, defaultFilters.scope, initialFilter);
    initialFilter = R.assocPath([PslFilterKeys.LOCATION, 'selected'], [], initialFilter);
    initialFilter = setSelectedFiltersBykey(PslFilterKeys.ZONE, defaultFilters.zones, initialFilter);
    initialFilter = R.assocPath([PslFilterKeys.FROM_SPEND, 'selected'], "", initialFilter);
    initialFilter = R.assocPath([PslFilterKeys.TO_SPEND, 'selected'], "", initialFilter);
    initialFilter = R.assocPath([PslFilterKeys.PARENT_PSL, 'selected'], "", initialFilter);
    initialFilter = R.assocPath([PslFilterKeys.CURRENCY_SPEND, 'selected'], "", initialFilter);
    initialFilter = R.assocPath([PslFilterKeys.SHOW_OBSOLETE, 'selected'], defaultFilters.showObsolete, initialFilter);
    initialFilter = R.assocPath([PslFilterKeys.SUPPLIER_DISCRIPTION, 'selected'], defaultFilters.supplierDescription, initialFilter);
    initialFilter = setSelectedFiltersBykey(PslFilterKeys.WAY_OF_BUYING, defaultFilters.wayOfBuying, initialFilter);
    if (defaultFilters.pslCreationAndValidityDate) {
      initialFilter = setSelectedFiltersBykey(PslFilterKeys.PSL_CREATION_AND_VALIDITY_DATE, defaultFilters.pslCreationAndValidityDate[0], initialFilter);
    }
  }
  return {
    ...newQuery(initialFilter),
    term: term,
  };
}

export function pslFiltersToBackend(query: any, userScope: IUserScopeFilters, time: SupplierFilterTabs = null, exactId = null,  pslSearch = null, materialSearch = null) {
  let tempId: any = checkPslExactId(exactId);
  let PSL_163770_VIEW_CHILD_PSL_FROM_PARENT = PataflagService.getInstance().flag(Features.PSL_163770_VIEW_CHILD_PSL_FROM_PARENT);
  let isChildrenOf = query?.filters["childrenOf"]?.selected.toString().length > 0;
  return {
    spendCatL1s: (query.filters?.SpendCat1Code?.selected ??[]).concat(userScope?.spendCatL1s ?? []),
    spendCatL2s: (query.filters?.SpendCat2Code?.selected ??[]).concat(userScope?.spendCatL2s ?? []),
    spendCatL3s: (query.filters?.SpendCat3Code?.selected ??[]).concat(userScope?.spendCatL3s ?? []),
    companies: (query.filters?.CompanyCode?.selected ?? []).concat(userScope?.companies ?? []),
    markets: (query.filters?.Markets?.selected ?? []).concat(userScope?.markets ?? []),
    statuses: query.filters?.statusId?.selected ?? [],
    complianceStatuses: query.filters?.ComplianceStatuses?.selected ? query.filters.ComplianceStatuses.selected : [],
    suppliers: query.filters?.SupplierId?.selected ? query.filters.SupplierId.selected : [],
    materials: query.filters?.MaterialNumber?.selected ? query.filters.MaterialNumber.selected : [],
    businessUnits: query.filters?.BusinessUnits?.selected ? query.filters.BusinessUnits.selected : [],
    strategicBuyers: query.filters?.StrategicBuyers?.selected ? query.filters.StrategicBuyers.selected : [],
    pslStatuses: query.filters?.PslStatuses?.selected ? query.filters.PslStatuses.selected : [],
    supplierDiversity: query.filters?.SupplierDiversity?.selected ? query.filters.SupplierDiversity.selected : [],
    wayOfBuying: query.filters?.WayOfBuying?.selected ? query.filters.WayOfBuying.selected : [],
    scope: query?.filters?.taskKind !== "APPROVAL" ? query.filters?.Scope?.selected ? query.filters.Scope.selected : null : null,
    time: mapTimeToBackend(time),
    exactId: PSL_163770_VIEW_CHILD_PSL_FROM_PARENT ? (exactId && parseInt(tempId) && !isChildrenOf) ? parseInt(tempId) : null : (exactId && parseInt(tempId)) ? parseInt(tempId) : null,
    taskKind: query?.filters?.taskKind ? query.filters.taskKind : null,
    createdByUserId: query?.filters?.createdByUserId ? query.filters.createdByUserId : null,
    fromSpend: query.filters?.fromSpend?.selected.length > 0 ? query.filters?.fromSpend?.selected : "",
    toSpend: query.filters?.toSpend?.selected.length > 0 ? query.filters?.toSpend?.selected : "",
    childrenOf: query.filters?.childrenOf?.selected.toString().length > 0 ? query.filters?.childrenOf?.selected.id : "",
    currencySpend: query.filters?.currencySpend?.selected.length > 0 ? query.filters?.currencySpend?.selected : "",
    plants: !R.isNil(query.filters?.plants?.selected) ? query.filters?.plants?.selected : "",
    orderBy: (query.orderBy && query.orderBy.length > 0 && query.orderBy[0].field) ? {
      field: capitalize(query.orderBy[0].field),
      direction: query.orderBy[0].direction
    } : null,
    pslCreationAndValidityDate:
    (Array.isArray(query?.filters?.creationAndValidityDate?.selected) && query?.filters?.creationAndValidityDate?.selected.length ===0) ? null:
    (query?.filters?.creationAndValidityDate?.selected.type && query?.filters?.creationAndValidityDate?.selected.startDate && query?.filters?.creationAndValidityDate?.selected.endDate)?
      {
        startDate: moment.utc(query?.filters?.creationAndValidityDate?.selected.startDate, 'YYYYMMDD'),
        endDate: moment.utc(query?.filters?.creationAndValidityDate?.selected.endDate, 'YYYYMMDD').add(23, 'hours').add(59, 'minutes').add(59, 'seconds').add(999, 'milliseconds'),
        type: query?.filters?.creationAndValidityDate?.selected.type
      }: null,
    zones: (query.filters?.zones?.selected)? query.filters?.zones?.selected: [],
    pslIds: (query.filters?.pslIds?.selected)? query.filters?.pslIds?.selected.map(el => ({id: el.id})): [],
    pslSearch: !PSL_163770_VIEW_CHILD_PSL_FROM_PARENT ? pslSearch : !isChildrenOf ? pslSearch : null,
    materialSearch: materialSearch,
    showObsolete: query.filters?.ShowObsolete.selected ?? false,
    FavoriteSwitchOn: query?.FavoriteSwitchOn ? query.FavoriteSwitchOn : false,
    supplierDescription: query.filters?.supplierDescription.selected
  };
}

function mapTimeToBackend (time: SupplierFilterTabs)  {
  switch(time) {
    case SupplierFilterTabs.EXPIRING:
      return 'expiring';
    case SupplierFilterTabs.ARCHIVED:
      return 'archived';
    case SupplierFilterTabs.ACTIVE:
      return 'active';
    default:
      return null;
  }
}
