import ODataClientWrapper, { List } from "src/app/shared/utils/odataClient.wrapper";
import { NbsData, NbsItemData, NbsMainteanceTemplateData, TrackerData } from "../interfaces/nbs";
import { AxiosWrapper } from "src/app/shared/utils/axios.wrapper";
import { withSystemAlias } from "src/app/shared/domains/auth/authentication.service";
import { createNbsBlockedParkFilters, createNbsFilters, templatePayloadForBE, trackerPayloadForBE } from "../helpers/functions";
import { ListResponse } from "src/app/contracts/domains/contract/contract.service";
import { Invoice } from "src/app/docFlows/invoices/domains/invoices/Invoice";
import { Query } from "src/app/shared/queryable/query";
import _ from "lodash";
import { SimpleRangeFilter } from "src/app/shared/domains/query/filter";
import { assignmentData } from "../components/nbsBlockedParkedAssignmentPopUp.component";
import { environment } from "src/app/shared/domains/core/environment";
import { AxiosRequestConfig } from "axios";

export class NbsService {

  static async fetchEasyRequestData(query: any, zone: string): Promise<any> {
    query.resource = "NbsFlowHeadSet";
    const odata = ODataClientWrapper.get().withSystemAlias(zone);
    const response: any = await odata.nbsFromQ(query)
      .count()
      .run();
    if (!response.data || !Array.isArray(response.data)) {
      throw new Error('Backend malformed json response:\n\n' + JSON.stringify(response));
    }
    return {
      count: parseInt(response.count),
      data: response.data.map(NbsData.FromBackend)
    }
  }

  static async fetchBlockedparkedData(query: any, zone: string, subTab: string): Promise<ListResponse<Invoice>> {
    let queryClone: Query = _.cloneDeep(query);
    queryClone.resource = "InvWrkFlwHdrSet";
    const odata = ODataClientWrapper.get().withSystemAlias(zone);

    // below code is to ensure chat and notes filter isnt set to SAP, also to filter based on chat and notes
    // we need to get full list of data then apply the filter
    const taskResponsible = query?.filters?.taskResponsible?.selected?.length > 0;

    if (queryClone.filters["remainderFilter"]["selected"] || taskResponsible) {
      delete queryClone["rows"];
      delete queryClone["page"];
      queryClone.filters["remainderFilter"]["selected"] = null;
      queryClone.filters["taskResponsible"]["selected"] = null;
    };

    queryClone.filters.Nbs_Flag = {
      "data": [],
      "key": "Nbs_Flag",
      "label": "",
      "selected": [{ title: "X", id: "X" }]
    };

    queryClone.filters[subTab] = {
      "data": [],
      "key": subTab,
      "label": "",
      "selected": [{ title: "X", id: "X" }]
    };

    if (queryClone.filters?.PriceRangeFrom?.selected || queryClone.filters?.PriceRangeTo?.selected) {
      const priceRange: SimpleRangeFilter = {
        priceFrom: +queryClone.filters.PriceRangeFrom.selected,
        priceTo: +queryClone.filters.PriceRangeTo.selected
      };

      queryClone.filters.PriceRange = {
        "data": [],
        "key": "PriceRange",
        "label": "",
        "selected": priceRange
      };

      delete queryClone.filters.PriceRangeFrom;
      delete queryClone.filters.PriceRangeTo;
    }

    const response: any = await odata.fromQInvoices(queryClone)
      .count()
      .run();

    if (!response.data || !Array.isArray(response.data)) {
      throw new Error('Backend malformed json response:\n\n' + JSON.stringify(response));
    }
    let transformedOutput: List<Invoice> = response.map(Invoice.FromBackend);

    if (query.filters["remainderFilter"]["selected"] || taskResponsible) {
      let tempData: Invoice[] = transformedOutput.data;

      if (query.filters["remainderFilter"]["selected"]) {
        let remainderData = [];

        const chatFilterSelected = query.filters["remainderFilter"]["selected"]?.map(item => item?.id);

        if (chatFilterSelected?.includes("Pending with Requestor")) { //orange
          remainderData = [...remainderData, ...tempData.filter(invoice => ((!invoice?.EnableNotification && invoice?.NotificationCount > 0) || invoice?.EnableNotification))];
        }
        if (chatFilterSelected?.includes("Pending with OB")) { //green
          remainderData = [...remainderData, ...tempData.filter(invoice => ((!invoice?.EnableNotification && +invoice?.NotificationCount === 0) && invoice?.InstructionDate))];
        }
        if (chatFilterSelected?.includes("No instruction sent")) { //grey
          remainderData = [...remainderData, ...tempData.filter(invoice => !invoice?.InstructionDate)];
        };
        tempData = remainderData;
      };

      if (taskResponsible) {
        const selectedTasks = query.filters["taskResponsible"]["selected"];
        let foundedTaskResponsible = [];

        selectedTasks?.map((item) => {
          foundedTaskResponsible.push(item.title.toLowerCase());
        });

        tempData = tempData.filter(invoice => foundedTaskResponsible.includes(invoice?.AssignedTo.toLowerCase()));
      }

      let tempCount: number = tempData.length;
      let top: number = 10;
      let skip: number = 0;
      if (query.page) { skip = (query.page * query.rows) };
      if (query.rows) {
        top = query.rows;
      }
      tempData = tempData.slice(skip, skip + top);
      transformedOutput = new List(tempData, tempCount);
    };
    return transformedOutput
  }

  static async fetchNbsItemData(systemAlias: string, prNo: string): Promise<any> {
    const resource = "NbsFlowItemSet";
    const response = await ODataClientWrapper.get()
      .withSystemAlias(systemAlias)
      .resource(`${resource}?$filter=PrNo eq '${prNo}'&$inlinecount=allpages`)
      .execute();

    if (!response.data || !response.data.d || !Array.isArray(response.data.d.results)) {
      throw new Error('Backend malformed json response:\n\n' + JSON.stringify(response));
    }

    const results = response.data.d.results;
    return {
      count: parseInt(response.data.d.__count),
      data: results.map(NbsItemData.FromBackend)
    }
  }

  static async appRejNbsCockpit(nbsAppRejData: any, systemAlias: string): Promise<any> {
    const path = "/api/sapdata/PostFromBody?action=" + 135 + "&query=IdSet"; // 135 is from UserInteractionType

    const buildBody = (nbsData: any) => {
      const bodyResult = [];
      nbsData.forEach(df => {
        bodyResult.push(
          {
            IdNo: "N2",
            PurchaseReqNo: df?.PrNo,
            PurchaseReqItemNo: df?.PrItem,
            Compliance_ind: df?.Compliance_ind,
            PR_PaymentTerms: df?.PR_PaymentTerms,
            Validity_Start: df?.Validity_Start,
            Validity_End: df?.Validity_End,
            Taxcode: df?.Taxcode,
            Status: df?.Status,
            Reason_Reject: df?.Reason_Reject,
            DelDate: df?.PrDeliveryDate,
          }
        )
      });
      return bodyResult;
    };
    const body = {
      results: {
        IdNo: "N2",
        DataChanged: "X",
        IdToPRItem: {
          results: buildBody(nbsAppRejData)
        }
      }
    };

    return await AxiosWrapper.post(path, body, {
      headers: withSystemAlias(systemAlias, {}, true)
    });
  }

  static async nbsSendEmail(body: any, systemAlias: string): Promise<any> {
    const path = "/api/NBSCockpit/SendEmailNbsPrApprovedState"; // 135 is from UserInteractionType

    return await AxiosWrapper.post(path, body, {
      headers: withSystemAlias(systemAlias, {}, true)
    });
  }

  static async saveEasyRequestFilters(filters: any): Promise<any> {
    const response = await AxiosWrapper.post(`/api/NBSCockpit/SaveFilters/EasyRequest`, filters);
    return response.data;
  }

  static async saveBlockedParkedFilters(filters: any): Promise<any> {
    const response = await AxiosWrapper.post(`/api/NBSCockpit/SaveFilters/BlockedParked`, filters);
    return response.data;
  }
  static async saveTrakerFilters(filters: any): Promise<any> {
    const response = await AxiosWrapper.post(`/api/NBSCockpit/SaveFilters/Tracker`, filters);
    return response.data;
  }
  static async saveFavFilters(formData: any): Promise<any> {
    const response = await AxiosWrapper.post(`/api/NBSCockpit/SaveFilters/FavNbsFlow?filterName=` + formData.name, createNbsFilters(formData));
    return response.data;
  }

  static async saveBlockedParkedFavFilters(formData: any): Promise<any> {
    const response = await AxiosWrapper.post(`/api/NBSCockpit/SaveFilters/FavBlockedparked?filterName=` + formData.name, createNbsBlockedParkFilters(formData));
    return response.data;
  }

  static async getFavFilters(): Promise<any> {
    const response = await AxiosWrapper.get(`/api/NBSCockpit/GetNbsFavFiltersNameList`);
    return response.data;
  }

  static async getBlockedParkedFavFilters(): Promise<any> {
    const response = await AxiosWrapper.get(`/api/NBSCockpit/GetBlockedParkedFavFiltersNameList`);
    return { nbsFavFilterNames: response.data?.blockedParkedFavFilterNames };
  }

  static async deleteFavFilters(filterName): Promise<any> {
    const encodedURI = encodeURI(`/api/NBSCockpit/DeleteNbsFavFiltersName?filterName=${filterName}`)
    const response = await AxiosWrapper.get(encodedURI)
    return response.data
  }

  static async deleteBlockedParkedFavFilters(filterName): Promise<any> {
    const encodedURI = encodeURI(`/api/NBSCockpit/DeleteBlockedparkedFavFilter?filterName=${filterName}`)
    const response = await AxiosWrapper.get(encodedURI)
    return response.data
  }

  static async renameFavFilters(oldNameAndNewName): Promise<any> {
    const { oldFilterName, newFilterName } = oldNameAndNewName
    const encodedURI = encodeURI(`/api/NBSCockpit/RenameNbsFavFiltersName?oldFilterName=${oldFilterName}&newFilterName=${newFilterName}`)
    const response = await AxiosWrapper.get(encodedURI)
    return response.data
  }

  static async renameBlockedParkedFavFilters(oldNameAndNewName): Promise<any> {
    const { oldFilterName, newFilterName } = oldNameAndNewName
    const encodedURI = encodeURI(`/api/NBSCockpit/RenameBlockedparkedFavFilters?oldFilterName=${oldFilterName}&newFilterName=${newFilterName}`)
    const response = await AxiosWrapper.get(encodedURI)
    return response.data
  }

  static async assignBlockedParkedData(payload: assignmentData[]): Promise<any> {
    const response = await AxiosWrapper.post("/api/NBSCockpit/SaveInvoiceTaskAssignment", payload);
    return response.data;
  }

  static async nbsCockpitMaintenanceImport(formData: any): Promise<any> {
    const options: AxiosRequestConfig = { timeout: parseInt(environment.REACT_APP_MRP_IMPORT_FILE_TIMEOUT) }
    const response = await AxiosWrapper.post(`/api/NBSCockpit/PostNBSInstructionFromExcel`, formData, options);
    return response.data;
  }

  static async postTrackerData(payload: any): Promise<any> {
    const response = await AxiosWrapper.post("/api/NBSCockpit/SaveOBAction", payload);
    return response.data;
  }

  static async cancelRemainder(invoiceNumber: string): Promise<any> {
    const response = await AxiosWrapper.post(`/api/NBSCockpit/DisableNotification?invoiceNumber=${invoiceNumber}`);
    return response.data;
  }

  static async reactivateRemainder(invoiceNumber: string): Promise<any> {
    const response = await AxiosWrapper.post(`/api/NBSCockpit/ReactivateNotification?invoiceNumber=${invoiceNumber}`);
    return response.data;
  }

  static async exportTrackerData(query: any, zone: string): Promise<any> {
    const response = await AxiosWrapper.post(`/api/NBSCockpit/ExportOBActionDetails?zone=${zone}`, trackerPayloadForBE(query.filters));
    return response.data;
  }

  static async getTrackerData(query: any, zone: string): Promise<{ count: number, data: TrackerData[] }> {

    const response = await AxiosWrapper.post(`/api/NBSCockpit/GetOBAction`, trackerPayloadForBE(query.filters),
      {
        params: {
          skip: query.page * query.rows,
          take: query.rows,
          zone: zone
        }
      });
    return {
      count: response.data?.count,
      data: response.data?.data?.length > 0 ? response.data.data.map(TrackerData.FromBackend) : []
    }
  }

  static async createOrUpdateNbsMaintenanceTemplate(postData: any): Promise<any> {
    const response = await AxiosWrapper.post(`/api/NBSCockpit/SaveNBSCockpitTemplate`, postData);
    return response.data;
  }

  static async deleteNbsMaintenanceTemplates(postData: any): Promise<any> {
    const response = await AxiosWrapper.post(`/api/NBSCockpit/DeleteNBSCockpitTemplate`, postData);
    return response.data;
  }

  static async fetchNbsMaintenanceTemplatesData(queryData: any): Promise<{ count: number, data: NbsMainteanceTemplateData[] }> {

    const response = await AxiosWrapper.post(`/api/NBSCockpit/GetNBSCockpitTemplates`, templatePayloadForBE(queryData.filters),
      {
        params: {
          skip: queryData?.page * queryData?.rows,
          take: queryData?.rows,
          searchTerm: ""
        }
      }
    );

    return {
      count: response?.data?.total,
      data: response?.data?.data?.length > 0 ? response?.data?.data?.map(NbsMainteanceTemplateData.FromBackend) : []
    }

  }

  static async saveTemplateFilters(filters: any): Promise<any> {
    const response = await AxiosWrapper.post(`/api/NBSCockpit/SaveFilters/NBSTemplate`, filters);
    return response.data;
  }

  static async exportNbsTemplateData(queryData: any): Promise<{ count: number, data: NbsMainteanceTemplateData[] }> {
    const response = await AxiosWrapper.post(`/api/NBSCockpit/GetNBSCockpitTemplatesAsExcel`,
      templatePayloadForBE(queryData.filters),
      {
        responseType: 'blob'
      }
    );
    return response.data;
  }

  static async exportTemplate(): Promise<any> {
    const response = await AxiosWrapper.get('/api/NBSCockpit/GetNBSCockpitInstructionExcelTemplate', {
      responseType: 'blob'
    });
    return response.data;
  }

  // static async exportNbsTemplateDataByEmail(queryData: any): Promise<{ count: number, data: NbsMainteanceTemplateData[] }> {
  //   const options: AxiosRequestConfig = { timeout: parseInt(environment.REACT_APP_MRP_EXPORT_FILE_TIMEOUT) }
  //   const response = await AxiosWrapper.post(`/api/NBSCockpit/GetNBSCockpitTemplatesAsExcel`, 
  //     templatePayloadForBE(queryData.filters),
  //     {
  //       ...options,
  //       responseType: 'blob',
  //       params: {
  //         skip: queryData?.page * queryData?.rows,
  //         take: queryData?.rows,
  //         searchTerm:""
  //       }
  //     }
  //   );

  //   return response?.data?.data?.length > 0 ? response.data.data : []
  // }

}