export enum UserInteractionType {
  EditContractValidityDateRange = 1,
  EditContractTargetQuantity = 2,
  EditContractItemPrice = 3,
  EditDraftContract = 4,
  ClonedContract = 5,
  DeletedDraftContract = 6,
  PublishedDraftContract = 7,
  ValidatedDraftContract = 8,
  SubmittedCompetitiveBidding = 9,
  EditedCompetitiveBidding = 10,
  ApprovedCompetitiveBidding = 11,
  RejectedCompetitiveBidding = 12,
  DownloadContractsInExcel = 13,
  EditedContractsFromExcel = 14,
  InsertedContractsFromExcel = 15,
  DownloadPslInExcel = 16,
  UploadPslFromExcel = 17,
  AddPsl = 18,
  EditPslForm = 19,
  AssociatedPrPoWithCompetitiveBidding = 20,
  AddedContractToFavorites = 21,
  RemovedContractFromFavorites = 22,
  VisitedSite = 23,
  OpenedContract = 24,
  VisitedSuppliersPage = 25,
  VisitedSourcingPage = 26,
  VisitedContractsPage = 27,
  VisitedDocumentsPage = 28,
  UnknownInteraction = 29,
  ReassignCompetitiveBiddingApprover = 30,
  DisassociatedPrPoWithCompetitiveBidding = 31,
  InsertMrpMapping = 32,
  UpdateMrpMapping = 33,
  DownloadMrpInExcel = 34,
  UploadMrpInExcel = 35,
  MadeApplicationFeedback = 36,
  CheckedContractCalendar = 37,
  CreatedContractCalendarEvent = 38,
  EditedContractCalendarEvent = 39,
  DeletedContractCalendarEvent = 40,
  UserRefusedToGiveAutomaticUserFeedback = 41,
  DownloadCompetitiveBiddingInExcel = 42,
  EditedCompetitiveBiddingApprovers = 43,
  DownloadContractCalendarEventExcel = 44,
  MarkMrpMappingAsDeleted = 45,
  EditedClmContractsFromExcel = 46,
  DownloadClmContractsInExcel = 47,
  SendClmContractToClm = 48,
  DeleteItemInClmContract = 49,
  CreateValidityPeriodClmContract = 50,
  UpdateItemPriceClmContract = 51,
  DeleteItemPriceClmContract = 52,
  UpdateClmContract = 53,
  DeleteClmContract = 54,
  ApprovePsl = 55,
  RejectPsl = 56,
  DeletePsl = 57,
  UndeletePsl = 58,
  DeletePslMaterial = 59,
  AddPslMaterialWithEasyRequest = 60,
  UpdateStatusOfMrp = 61,
  DownloadMrpExportFromBlob = 62,
  DownloadPslExportFromBlob = 63,
  DownloadMrpMappingsExportFromBlob = 64,
  ReactivatePsl = 65,
  EditPslMaterial = 66, //deprecated
  CheckedPslSuppliersHierarchy = 67,
  CheckedPslSupplierChildren = 68,
  CheckedPslAmdrSuppliers = 69,
  CheckedPslAmdrMaterials = 70,
  CheckedPslBusinessUnitsCheckedPslAmdrPlants = 71,
  CheckedPslMaterials = 72,
  CheckedPslEntries = 73,
  DownloadPslInExcelByEmail = 74,
  CheckedPslBusinessUnits = 75,
  DownloadPslMaterialsInExcel = 76,
  CheckedRequestCockpitOverview = 77,
  CheckedRequestCockpitItems = 78,
  CheckedRequestCockpitReceipts = 79,
  CheckedRequestCockpitInvoices = 80,
  CheckedRequestCockpitChatMessages = 81,
  CreatedRequestCockpitChatMessages = 82,
  CheckedRequestCockpitNotes = 83,
  CreatedRequestCockpitNotes = 84,
  AddedRequestCockpitToFavorites = 85,
  RemovedRequestCockpitFromFavorites = 86,
  EditedRequestCockpit = 87,
  EditedRequestCockpitPO = 88,
  ViewedInSAPRequestCockpitPO = 89,
  ViewedInSAPRequestCockpitPR = 90,
  PostedGRinMyGRAppRequestCockpit = 91,
  PostedGRinMyGRExceptionRequestCockpit = 92,
  ViewedGRinMyGRAppRequestCockpit = 93,
  PostedGRInSAPRequestCockpit = 94,
  ViewedInEasyBuyRequestCockpitPR = 95,
  ViewedInSAPRequestCockpitInvoiceIN = 96,
  ViewedInEasyBuyRequestCockpitInvoice = 97,
  OpenedWorkflowInvoiceIssues = 98,
  ApprovedPRForPAPRequestCockpit = 99,
  RejectedPRForPAPRequestCockpit = 100,
  DownloadInvoiceAttachmentRequestCockpit = 101,
  CheckedApprovedStateRequestCockpitPO = 102,
  ClosedPORequestCockpit = 103,
  CheckedBlockedInvoicesRequestCockpit = 104,
  UpdatedSpenderRequestCockpit = 105,
  EditInSAPContracts = 106,
  ViewInSAPContracts = 107,
  ViewedInSAPMrp = 108,
  ViewedInSAPRequestCockpitGR = 109,
  ViewedInSAPRequestCockpitIN = 110,
  ViewedInSAPRequestCockpit = 111,
  ViewedInSAPRequestCockpitIR = 112,
  ViewParentPsl = 113,
  ViewChildrenPsl = 114,
  ViewPslShoppingCart = 115,
  DescribeYourNeed = 116,
  ViewProfile = 117,
  AddLocalizer = 118,
  EditLocalizer = 119,
  DeleteLocalizer = 120,
  AddApprover = 121,
  EditApprover = 122,
  DeleteApprover = 123,
  AddPslMaterial = 124,
  ViewPslHistory = 125,
  UpdatePRWorkflow = 126,
  UpdatePROwnership = 127,
  RequestPOReopenOrClosure = 128,
  ViewPRApprovalStatus = 129,
  EditRejectedPSL = 130,
  NbsApproveRejectRequestCockpit=135,
  AccessNBSCockpit = 136,
  ViewPRDetailsNBSCockpit = 137,
  CheckNBSCockpitChatMessages = 138,
  CreateNBSCockpitChatMessages = 139,
  ApproveNBSCockpit = 140,
  RejectNBSCockpit = 141,
  PreviewAttachmentNBSCockpit = 142,
  DownloadAttachmentNBSCockpit = 143,
  ViewDetailsSAPNBSCockpit = 144,
  ViewDetailsRCNBSCockpit = 145,
  ExportInvoiceIssues = 146,
}
