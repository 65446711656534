import { newQuery, Query, setSelectedFiltersBykey, updateOrder } from "../../../shared/queryable/query";
import {
  FilterCategoryView,
  newDateRangeFilterCategory,
  newTextFilterCategory,
  TextFilter
} from "../../../shared/domains/query/filter";
import { DateTime } from 'luxon';
import * as R from "ramda";
import { Dictionary } from "ramda";
import { IDocflowFilters } from "../../../shared/domains/user/user";
import { OrderDirection } from "../../../../lib/odata";


export const prApprovalDropDown = [
  { value: "P", label: "Compliant" },
  { value: "X", label: "Non - Compliant" },
];

export const searchOptions = [
  { value: "pr_num_ser", label: "Purchase Requisition" },
  { value: "po_num_ser", label: "Purchase Order" },
  { value: "supp_num_ser", label: "Supplier Number" },
  { value: "supp_name_ser", label: "Supplier Name" },
  { value: "mat_num_ser", label: "Material Number" },
  { value: "mat_des_ser", label: "Material Description" },
  { value: "invloce_ser", label: "Nestlé Invoice Number" },
  { value: "supp_inv_ser", label: "Supplier Invoice Number" },
];

export const searchNewOptions = [
  { value: "pr_num_ser", label: "Purchase Requisition" },
  { value: "po_num_ser", label: "Purchase Order" },
  { value: "Project_ser", label: "Project/Order Title" },
  { value: "supp_num_ser", label: "Supplier Number" },
  { value: "supp_name_ser", label: "Supplier Name" },
  { value: "mat_num_ser", label: "Material Number" },
  { value: "mat_des_ser", label: "Material Description" },
  { value: "invloce_ser", label: "Nestlé Invoice Number" },
  { value: "supp_inv_ser", label: "Supplier Invoice Number" },
];

export const searchOptionsI = [
  { value: "invloce_ser", label: "Nestlé Invoice Number" },
  { value: "supp_inv_ser", label: "Supplier Invoice Number" },
  { value: "pr_num_ser", label: "Purchase Requisition" },
  { value: "po_num_ser", label: "Purchase Order" },
];
export const prPoTypeOptions = [
  { id: "NB", title: "Standard PR/PO" },
  { id: "FO", title: "Framework Requisition/Order" },
  { id: "Z004", title: "Capex PR/PO" },
  { id: "ZARB", title: "ARIBA Purchase Requisition/Order" },
  { id: "Z008", title: "Call Off PR/PO" },
  { id: "Z014", title: "Transportation PR/PO" },
  { id: "Z018", title: "Call Off PO Manual" },
];



export enum DocFlowFilterKeys {
  PURCH_ORG = 'PurchOrg',
  COMP_CODE = 'POCompanyCode',
  PURCH_GROUP = 'PurchGroup',
  PLANT = 'PRPlant',
  VENDOR_CODE = 'VendorNo',
  COST_CENTER = 'CostCenter',
  PROJECT_ID = 'WbsElement',
  PO_REQUESTOR = 'PoCreatorName',
  PR_REQUESTOR = 'PrCreatorName',
  REQUISITIONER = 'Requisitioner',
  CREATION_DATE = 'CreationDate',
  COMPLIANCE_INDICATOR = 'CollectiveNumber',
  STATUS = 'Status',
  DELIVERY_DATE = 'DelDate',
  PRICE_RANGE_FROM = 'PriceRangeFrom',
  PRICE_RANGE_TO = 'PriceRangeTo',
  PRICE_RANGE = 'PriceRange',
  CURRENCY = 'Currency',
  MATERIAL = 'MaterialNo',
  PO_NUMBER = 'PurchaseOrdNo',
  PR_NUMBER = 'PurchaseReqNo',
  ARIBA_NUMBER = 'AribaPr',
  PR_PO_TYPE = "DocType",
  ALERT_TYPE_BLOCK = "Gr_Rec",
  ALERT_TYPE_PRPODIFF = "POPR_Diff",
  CHAT_FILTERS = "chatFilter",
  NOTES_FILTERS = "notesFilter",
  EXTCONTNO = "ExtContrNo",
  PPMCSTPLN = "PPMCstPln",
  SOURCING_ID = "Sourcing_ID",
  EXTREQNO = "ExtReqNo",
  PSL_ID = "Psl_ID",
}

export const DocumentFlowFilters = [
  { key: DocFlowFilterKeys.COMP_CODE, label: 'Company code *' },
  { key: DocFlowFilterKeys.PURCH_ORG, label: 'Purchasing Organization' },
  { key: DocFlowFilterKeys.PLANT, label: 'Plant' },
  { key: DocFlowFilterKeys.PURCH_GROUP, label: 'Purchasing Group' },
  { key: DocFlowFilterKeys.VENDOR_CODE, label: 'Supplier' },
  { key: DocFlowFilterKeys.COST_CENTER, label: 'Cost Center' },
  { key: DocFlowFilterKeys.PROJECT_ID, label: 'Project Id / WBS' },
  { key: DocFlowFilterKeys.PO_REQUESTOR, label: 'PO Creator' },
  { key: DocFlowFilterKeys.PR_REQUESTOR, label: 'Requestor' },
  { key: DocFlowFilterKeys.REQUISITIONER, label: 'Spender' },
  { key: DocFlowFilterKeys.CREATION_DATE, label: 'Document Date' },
  { key: DocFlowFilterKeys.COMPLIANCE_INDICATOR, label: 'Compliance Indicator' },
  { key: DocFlowFilterKeys.STATUS, label: 'Status' },
  { key: DocFlowFilterKeys.PRICE_RANGE_FROM, label: 'Price from' },
  { key: DocFlowFilterKeys.PRICE_RANGE_TO, label: 'Price to' },
  { key: DocFlowFilterKeys.PRICE_RANGE, label: 'PriceRange' },
  { key: DocFlowFilterKeys.CURRENCY, label: 'Currency' },
  { key: DocFlowFilterKeys.MATERIAL, label: 'Material' },
  { key: DocFlowFilterKeys.PO_NUMBER, label: 'PO Number' },
  { key: DocFlowFilterKeys.PR_NUMBER, label: 'PR Number' },
  { key: DocFlowFilterKeys.PR_PO_TYPE, label: 'PR/PO Type' },
  { key: DocFlowFilterKeys.ALERT_TYPE_BLOCK, label: 'Alert Filters Block' },
  { key: DocFlowFilterKeys.ALERT_TYPE_PRPODIFF, label: 'Alert Filters PR/PO Diff' },
  { key: DocFlowFilterKeys.CHAT_FILTERS, label: 'Chat Filters' },
  { key: DocFlowFilterKeys.NOTES_FILTERS, label: 'Notes Filters' },
  { key: DocFlowFilterKeys.EXTCONTNO, label: 'Ext. Contract Number' },
  { key: DocFlowFilterKeys.PPMCSTPLN, label: 'PPM Cost Plan' },
  { key: DocFlowFilterKeys.SOURCING_ID, label: 'Sourcing ID' },
  { key: DocFlowFilterKeys.EXTREQNO, label: 'External Request No' },
  { key: DocFlowFilterKeys.PSL_ID, label: 'PSL ID' },
];

const DefaultSortField = "POCreationDate";

const initial = DocumentFlowFilters.map(x => newTextFilterCategory(x.key, x.label));
const initialDictionary = R.indexBy(R.prop('key'), initial);

export const todayLessThreeMonths = DateTime.local().minus({ months: 3 }).toFormat('yyyyMMdd');
export const todayLessOneMonth = DateTime.local().minus({ months: 1 }).toFormat('yyyyMMdd');
export const today = DateTime.local().toFormat('yyyyMMdd');

export function newInitialFilters(defaultCompany?: TextFilter): Dictionary<FilterCategoryView> {
  return defaultCompany ?
    setSelectedFiltersBykey(DocFlowFilterKeys.COMP_CODE, [defaultCompany], initialDictionary) :
    initialDictionary;
}

export function getInitialFiltersForDocFlow(defaultCompany?: TextFilter, defaultFilters?: IDocflowFilters) {
  let initialFilter: Dictionary<FilterCategoryView> = R.mergeAll(
    [
      newInitialFilters(defaultCompany),
      {
        DelDate: newDateRangeFilterCategory(DocFlowFilterKeys.DELIVERY_DATE, 'Delivery Date', {
          startDate: todayLessOneMonth,
          endDate: today
        }, {
          startDate: null,
          endDate: null
        }),
        PriceRange: {
          data: {
            priceFrom: null,
            priceTo: null
          },
          key: "PriceRange",
          label: "PriceRange",
          selected: {
            priceFrom: null,
            priceTo: null
          }
        },
      }
    ]
  );

  initialFilter = setSelectedFiltersBykey(DocFlowFilterKeys.CHAT_FILTERS, null, initialFilter);
  initialFilter = setSelectedFiltersBykey(DocFlowFilterKeys.NOTES_FILTERS, null, initialFilter);
  initialFilter = setSelectedFiltersBykey(DocFlowFilterKeys.ALERT_TYPE_BLOCK, null, initialFilter);
  initialFilter = setSelectedFiltersBykey(DocFlowFilterKeys.ALERT_TYPE_PRPODIFF, null, initialFilter);

  if (defaultFilters) {
    let filters: IDocflowFilters = R.assoc('companies', R.filter((c: TextFilter) => !!c.id)(defaultFilters.companies), defaultFilters);
    if (R.isEmpty(R.prop('companies', filters)) && !R.isNil(defaultCompany)) {
      filters = R.assoc('companies', [defaultCompany], filters);
    }
    initialFilter = setSelectedFiltersBykey(DocFlowFilterKeys.PURCH_ORG, filters.pOrgs, initialFilter);
    initialFilter = setSelectedFiltersBykey(DocFlowFilterKeys.COMP_CODE, filters.companies, initialFilter);
    initialFilter = setSelectedFiltersBykey(DocFlowFilterKeys.PURCH_GROUP, filters.pGroups, initialFilter);
    initialFilter = setSelectedFiltersBykey(DocFlowFilterKeys.PLANT, filters.plants, initialFilter);
    initialFilter = setSelectedFiltersBykey(DocFlowFilterKeys.VENDOR_CODE, filters.vendorNo, initialFilter);
    initialFilter = setSelectedFiltersBykey(DocFlowFilterKeys.COST_CENTER, filters.costCenter, initialFilter);
    initialFilter = setSelectedFiltersBykey(DocFlowFilterKeys.PROJECT_ID, filters.projectId, initialFilter);
    initialFilter = setSelectedFiltersBykey(DocFlowFilterKeys.REQUISITIONER, filters.requester, initialFilter);
    initialFilter = setSelectedFiltersBykey(DocFlowFilterKeys.PO_REQUESTOR, filters.poRequestor, initialFilter);
    initialFilter = setSelectedFiltersBykey(DocFlowFilterKeys.PR_REQUESTOR, filters.prRequestor, initialFilter);
    initialFilter = setSelectedFiltersBykey(DocFlowFilterKeys.COMPLIANCE_INDICATOR, filters.complianceIndicator, initialFilter);
    initialFilter = setSelectedFiltersBykey(DocFlowFilterKeys.STATUS, filters.status, initialFilter);

    initialFilter = R.assocPath([DocFlowFilterKeys.PRICE_RANGE, 'selected'],
      filters.priceRange ? filters.priceRange[0] :
        {
          priceFrom: null,
          priceTo: null
        }, initialFilter);
    initialFilter = R.assocPath([DocFlowFilterKeys.CREATION_DATE, 'selected'],
      filters.creationDate.buttonName || filters.creationDate.type ? filters.creationDate :
        {
          "type": "PO",
          "buttonName": null,
          "startDate": null,
          "endDate": null
        }, initialFilter);
    initialFilter = setSelectedFiltersBykey(DocFlowFilterKeys.CURRENCY, filters.currency, initialFilter);
    initialFilter = setSelectedFiltersBykey(DocFlowFilterKeys.MATERIAL, filters.material, initialFilter);

    initialFilter = setSelectedFiltersBykey(DocFlowFilterKeys.PO_NUMBER, filters.poNumber, initialFilter);
    initialFilter = setSelectedFiltersBykey(DocFlowFilterKeys.PR_NUMBER, filters.prNumber, initialFilter);
    initialFilter = setSelectedFiltersBykey(DocFlowFilterKeys.PR_PO_TYPE, filters.docType, initialFilter);

    initialFilter = setSelectedFiltersBykey(DocFlowFilterKeys.EXTCONTNO, filters.extContactNumber, initialFilter);
    initialFilter = setSelectedFiltersBykey(DocFlowFilterKeys.PPMCSTPLN, filters.ppmCostPlan, initialFilter);
    initialFilter = setSelectedFiltersBykey(DocFlowFilterKeys.SOURCING_ID, filters.sourcingID, initialFilter);
    initialFilter = setSelectedFiltersBykey(DocFlowFilterKeys.EXTREQNO, filters.extReqNumber, initialFilter);
    initialFilter = setSelectedFiltersBykey(DocFlowFilterKeys.PSL_ID, filters.pslID, initialFilter);

    if (filters.deliveryDateRange) {
      initialFilter = setSelectedFiltersBykey('DelDate', filters.deliveryDateRange, initialFilter);
    }
  }
  return initialFilter;
}

export function newDocumentFlowQuery(defaultCompany?: TextFilter, defaultFilters?: IDocflowFilters, term?: string): Query {
  let initialFilter = getInitialFiltersForDocFlow(defaultCompany, defaultFilters);

  return updateOrder({
    ...newQuery(initialFilter),
    term: term
  }, DefaultSortField, OrderDirection.DESC);
}
