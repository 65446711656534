import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { appHistory, store } from "./application.reducers";
import { Provider } from "react-redux";
import './application.styles.css';
import { muiTheme } from "./application.theme";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import { ErrorBoundary } from "./shared/components/error";
import ScrollToTop from './shared/components/scroll';
import { init, Integrations } from '@sentry/browser';
import { ConnectedRouter } from "connected-react-router";
import { ChatBotContainer } from "./chatBot/containers/chatbot.container";
import { Me } from "./shared/containers/me/me";
import Dashboard from "./shared/containers/dashboard";

import { FullWidthLoadingComponent } from "./shared/components/loading";
import { isLocalEnvironment, retry } from "./shared/utils/global";
import { QueryClient, QueryClientProvider } from "react-query";
import OpsBuyerOnlyRoute from './OpsBuyerOnlyRoute';
import PslCatalogsRoute from './pslCatalogs/misc/pslCatalogsRoute';
import { NbsCockpitTabs } from './nbsCockpit/helpers/constants';

const LandingPage = lazy(() => retry(() => import('./landing/containers/landing.page')));
const ContractsContainer = lazy(() => retry(() => import('./contracts/containers/contracts/list/contracts.container')));
const ContractUploadContainer = lazy(() => retry(() => import('./contracts/containers/upload/contractUpload')));
const ChatContainer = lazy(() => retry(() => import('./contracts/containers/chat/chat.container')));
const TenderCalendarContainer = lazy(() => retry(() => import('./tenderCalendar/containers/calendar.container')));
const DocFlowsContainer = lazy(() => retry(() => import('./docFlows/containers/list/docFlows.container')));
const CbListContainer = lazy(() => retry(() => import('./competitiveBidding/containers/list/cbList.container')));
const CbSummaryContainer = lazy(() => retry(() => import('./competitiveBidding/containers/summary/cbSummary.container')));
const CBFormContainer = lazy(() => retry(() => import('./competitiveBidding/containers/request/cbForm.container')));
const BAContainer = lazy(() => retry(() => import('./purchaseRequisition/BusinessApprovals/purchaseRequests')));
const PAContainer = lazy(() => retry(() => import('./purchaseRequisition/ProcurementApprovals/purchaseRequests')));
const PRMainContainer = lazy(() => retry(() => import('./purchaseRequisition/purchaseRequestHeader.container')));
const PreferredSuppliersContainer = lazy(() => retry(() => import('./preferredSuppliers/containers/list/preferredSuppliers.container')));
const PslFormContainer = lazy(() => retry(() => import('./preferredSuppliers/containers/pslForm/pslForm.container')));
const PslAdminContainer = lazy(() => retry(() => import('./preferredSuppliers/containers/admin/admin.page')));
const PslMaintenanceContainer = lazy(() => retry(() => import('./preferredSuppliers/containers/maintenance/maintenancePage')));
const PslHistoryContainer = lazy(() => retry(() => import('./preferredSuppliers/containers/history/history.container')));
const ImportPSLContainer = lazy(() => retry(() => import('./preferredSuppliers/containers/import/importPSL.container')));
const ExportPSLContainer = lazy(() => retry(() => import('./preferredSuppliers/containers/export/ExportPsl.container')));
const ExportMRPContainer = lazy(() => retry(() => import('./preferredSuppliers/containers/export/ExportMrp.container')));
const ExportMRPMappingsContainer = lazy(() => retry(() => import('./preferredSuppliers/containers/export/ExportMrpMappings.container')));
const MrpContainer = lazy(() => retry(() => import('./mrp/containers/list/mrp.container')));
const MrpFormContainer = lazy(() => retry(() => import('./mrp/containers/mrpForm/mrpForm.container')));
const MrpHistoryPage = lazy(() => retry(() => import('./mrp/containers/history/mrpHistory.page')));
const ImportMRPContainer = lazy(() => retry(() => import('./mrp/containers/import/importMRP.container')));
const DownloadFileContainer = lazy(() => retry(() => import('./mrp/containers/download/downloadFile.container')));
const PRLinks = lazy(() => retry(() => import('./purchaseRequisition/request.page')));
const ApprovePrViaEmailContainer = lazy(() => retry(() => import('./purchaseRequisition/approvePrViaEmail/approvePrViaEmail')));
const ApprovalStatusContainer = lazy(() => retry(() => import('./purchaseRequisition/ApprovalStatus/approvalStatus.container')));
const ApprovalHistoryContainer = lazy(() => retry(() => import('./purchaseRequisition/ApprovalHistory/approvalHistory.container')));
const DashboardContainer = lazy(() => retry(() => import('./admin/containers/statistics/dashboard.container')));
const FeedbackListContainer = lazy(() => retry(() => import('./admin/containers/list/feedbackList.container')));
const PRSubstitutionContainer = lazy(() => retry(() => import('./purchaseRequisition/PRSubstitution/prSubstitution.container')));
const NbsCockpitPage = lazy(() => retry(() => import('./nbsCockpit/containers/nbsCockpit.page')));
const FeedbackCockpitContainer = lazy(() => retry(() => import('./feedbackCockpit/containers/feedbackCockpit.page')));
const RequestCockpitAdminContainer = lazy(() => retry(() => import('./docFlows/containers/admin/admin.page')));
const NbsCockpitMaintenancePage = lazy(() => retry(() => import('./nbsCockpit/containers/nbsCockpitMaintenance.page')));
const NotAuthorizedPage = lazy(() => retry(() => import('./notAuthorizedPage')));
const PslCatalogsContainer = lazy(() => retry(() => import('./pslCatalogs/pslCatalogPage')));
const ImportNbsMaintenanceContainer = lazy(() => retry(() => import('./nbsCockpit/containers/nbsCockpitMaintenanceImport.page')));

if (!isLocalEnvironment()) {
  init({
    dsn: "https://e1bce5649e7946338a914cbfcd714115@sentry.io/1516030",
    environment: process.env.REACT_APP_ENV,
    integrations: [
      new Integrations.Breadcrumbs({
        console: false
      })
    ]
  });
}

export default function Application() {
  const queryClient = new QueryClient()
  return (
    <MuiThemeProvider theme={muiTheme}>
      <BrowserRouter>
        <ScrollToTop>
          <Provider store={store}>
            <ConnectedRouter history={appHistory}>
              <MuiPickersUtilsProvider utils={LuxonUtils}>
                <QueryClientProvider client={queryClient}>
                  <ErrorBoundary>
                    <Me>
                      <Dashboard>
                        <Suspense fallback={<FullWidthLoadingComponent text={'Loading component...'} />}>
                          <Helmet
                            titleTemplate={process.env.REACT_APP_ENV !== "prod" ? `[${process.env.REACT_APP_ENV}] %s | S2P Browser` : '%s | S2P Browser'}
                            defaultTitle={process.env.REACT_APP_ENV !== "prod" ? `[${process.env.REACT_APP_ENV}] S2P Browser` : "S2P Browser"}>
                            <meta charSet="utf-8" />
                          </Helmet>
                          <Switch>
                            <Route exact path={"/"} component={LandingPage} />
                            <Route exact path={'/contract'} component={ContractsContainer} />
                            <Route path={'/contracts'} component={ContractsContainer} />
                            <Route path={'/contract/upload'}
                              render={props => <ContractUploadContainer {...props} isCreatingNew={true} />} />
                            <Route path={'/contract/change'}
                              render={props => <ContractUploadContainer {...props} isCreatingNew={false} />} />
                            <Route path={'/contract/chat'} component={ChatContainer} />
                            <Route path={'/contract/appointments'} component={TenderCalendarContainer} />
                            <Route path={'/contract/:contractNo'} component={ContractsContainer} />
                            <Route exact path={'/document-flow'} component={DocFlowsContainer} />
                            <Route exact path={'/competitive-bidding'} component={CbListContainer} />
                            <Route exact path={'/competitive-bidding/link-df'} component={CbListContainer} />
                            <Route path={'/competitive-bidding/summary'} component={CbSummaryContainer} />
                            <Route exact path={'/competitive-bidding/create'} component={CBFormContainer} />
                            <Route path={'/competitive-bidding/from-df/create'} component={CBFormContainer} />
                            <Route path={'/competitive-bidding/:cbId/update'} component={CBFormContainer} />
                            <Route path={'/BAS'} component={BAContainer} />
                            <Route path={'/business-approval'} component={BAContainer} />
                            <Route path={'/PAS'} component={PAContainer} />
                            <Route path={'/purchasing-approval'} component={PAContainer} />
                            <Route path={'/purchase-request'} component={PRMainContainer} />
                            <PslCatalogsRoute exact path={'/psl'} component={PreferredSuppliersContainer} />
                            <PslCatalogsRoute exact path={'/psl/mytasks/:urlTaskTab?'} render={(props) => <PreferredSuppliersContainer {...props} isMyTasks={true} />} />
                            <PslCatalogsRoute exact path={'/psl/mytasks/approval/:urlTaskTab?'} render={(props) => <PreferredSuppliersContainer {...props} isMyTasks={true} />} />
                            <PslCatalogsRoute exact path={'/psl/create'} render={(props) => <PslFormContainer {...props} isCreate={true} />} />
                            <PslCatalogsRoute exact path={'/psl/edit/:pslId'} render={(props) => <PslFormContainer {...props} isEditPsl={true} />} />
                            <PslCatalogsRoute exact path={'/psl/reactivate/:pslId'} render={(props) => <PslFormContainer {...props} isEditPsl={true} isReactivation={true} />} />
                            <PslCatalogsRoute exact path={'/psl/localize/:pslId'} render={(props) => <PslFormContainer {...props} isEditPsl={true} isLocalizer={true} />} />
                            <PslCatalogsRoute exact path={'/psl/edit/:pslId/addmaterials/'} render={(props) => <PslFormContainer {...props} isAddMaterials={true} />} />
                            <PslCatalogsRoute exact path={'/psl/history/:pslId'} render={(props) => <PslHistoryContainer {...props} />} />
                            <PslCatalogsRoute exact path={'/psl/history/:pslId/material/:materialId'} render={(props) => <PslHistoryContainer {...props} />} />
                            <PslCatalogsRoute exact path={'/psl/import'} component={ImportPSLContainer} />
                            <PslCatalogsRoute exact path={'/psl/export/:id'} component={ExportPSLContainer} />
                            <Route exact path={'/mrp/export/:id'} component={ExportMRPContainer} />
                            <Route exact path={'/mrp/mappings/export/:id'} component={ExportMRPMappingsContainer} />
                            <PslCatalogsRoute exact path={'/psl/admin'} component={PslAdminContainer} />
                            <Route exact path={'/pslCatalogs'} component={PslCatalogsContainer} />
                            <PslCatalogsRoute exact path={'/psl/maintenance'} component={PslMaintenanceContainer} />
                            <Route exact path={'/mrp'} component={MrpContainer} />
                            <Route exact path={'/mrp/create'} render={(props) => <MrpFormContainer {...props} isCreate={true} />} />
                            <Route exact path={'/mrp/edit/:mrpId'} render={(props) => <MrpFormContainer {...props} isEdit={true} />} />
                            <Route exact path={'/mrp/history/:mrpId'} render={(props) => <MrpHistoryPage {...props} />} />
                            <Route exact path={'/mrp/import'} component={ImportMRPContainer} />
                            <Route exact path={'/download/:type/:file'} component={DownloadFileContainer} />
                            <Route path={'/pr-links'} component={PRLinks} />
                            <Route path={'/approve-pr-via-email'} component={ApprovePrViaEmailContainer} />
                            <Route path={'/approval-status'} component={ApprovalStatusContainer} />
                            <Route path={'/history'} component={ApprovalHistoryContainer} />
                            {/* <Route path={'/faq'} component={ChatBotContainer}/> */}
                            <Route path={'/dashboard'} component={DashboardContainer} />
                            <Route exact path={'/admin/feedback-report'} component={FeedbackListContainer} />
                            <Route exact path={'/admin/feedback-report/:feedBackId'} component={FeedbackListContainer} />
                            <Route exact path={'/feedback-report'} component={FeedbackCockpitContainer} />
                            <Route exact path={'/feedback-report/:feedBackId'} component={FeedbackCockpitContainer} />
                            <Route path={'/pr-substitution'} component={PRSubstitutionContainer} />
                            <OpsBuyerOnlyRoute exact path={'/nbs-cockpit/maintenance'} component={NbsCockpitMaintenancePage} />
                            <OpsBuyerOnlyRoute exact path={'/nbs-cockpit/maintenance/import'} component={ImportNbsMaintenanceContainer} />
                            <OpsBuyerOnlyRoute exact path={'/nbs-cockpit'} render={(props) => <NbsCockpitPage {...props} activeTab={NbsCockpitTabs.EASY_REQUEST} />} />
                            <OpsBuyerOnlyRoute exact path={'/nbs-cockpit/ob-validation'} render={(props) => <NbsCockpitPage {...props} activeTab={NbsCockpitTabs.EASY_REQUEST} />} />
                            <OpsBuyerOnlyRoute exact path={'/nbs-cockpit/blocked-parked'} render={(props) => <NbsCockpitPage {...props} activeTab={NbsCockpitTabs.BLOCKED_PARKED} />} />
                            <Route path={'/document-flow/admin'} component={RequestCockpitAdminContainer} />
                            <Route path={'/notauthorized'} component={NotAuthorizedPage} />
                            <Route path="*" render={(props) => <Redirect to="/" />} />
                          </Switch>
                        </Suspense>
                      </Dashboard>
                    </Me>
                  </ErrorBoundary>
                </QueryClientProvider>
              </MuiPickersUtilsProvider>
            </ConnectedRouter>
          </Provider>
        </ScrollToTop>
      </BrowserRouter>
    </MuiThemeProvider>
  );
}