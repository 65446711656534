import React, { useEffect } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton } from '@material-ui/core';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { newEditRequestPageStyles } from 'src/app/docFlows/components/itemDrilldownTable/editRequestAllLines/AllSections/newEditRequest/newEditRequestPageStyles';
import { Invoice } from 'src/app/docFlows/invoices/domains/invoices/Invoice';
import { NbsBlockedParkedAssignmentPopUp } from './nbsBlockedParkedAssignmentPopUp.component';
import { useDispatch, useSelector } from 'react-redux';
import { ChatActions as invoiceChatActions, makeInvoiceChatId } from "src/app/docFlows/reducers/invoiceChat.reducer";
import { RootStateStore } from 'src/app/application.reducers';
import moment from 'moment';
import { useCancelRemainder, useReactivateRemainder } from '../hooks/nbsCockpitCustomHooks';
import { NbsBlockedParkedTabs } from '../helpers/constants';
import { DocumentFlowService } from 'src/app/docFlows/domains/documentFlow/documentFlow.service';
import { UserInteractionType } from 'src/app/shared/domains/user/userInteractionType';
import { NbsSendInstructionPopUpComponent } from './nbsSendInstructionPopUp.component';

export const sendRemainder = (dispatch, lastMessage, actualItem, setSuccessMessage) => {
    console.log(lastMessage);
    const messageBody = {
        companyName: "",
        companyId: actualItem.CompCode,
        prNo: actualItem.PrNo,
        poNo: actualItem.PoNo,
        systemAlias: actualItem.SystemAlias,
        docDate: "",
        prPotype: "PO",
        message: "", // this will be taken care by BE
        msgSendTo: lastMessage?.mentionedPersons,
        mentionedEmailAddresses: lastMessage?.mentionedEmailAddresses,
        attachments: lastMessage?.attachments,
        invoiceNumber: actualItem.InvDocNo,
        year: actualItem.FiscalYr,
        applicationCreated: "NBS Cockpit",
        tabCreated: "Blocked&Parked",
        reminderFlag: true
    };
    dispatch({
        type: invoiceChatActions.onNewSendMessage,
        payload: messageBody
    });
    setSuccessMessage("Reminder sent to the Requestor");
};

export default function NbsBlockedParkedMoreButton({ invoiceItem,
    onSuccessAssignment,
    setSuccessMessage,
    reFetchBlockedParkedData,
    selectedSubTab,
    sendInstructions,
    zone }: {
        invoiceItem: Invoice,
        onSuccessAssignment: any,
        setSuccessMessage: React.Dispatch<React.SetStateAction<string>>,
        reFetchBlockedParkedData: any,
        selectedSubTab: NbsBlockedParkedTabs,
        sendInstructions: Function,
        zone: string
    }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const styles = newEditRequestPageStyles({});
    const [open, setOpen] = React.useState(false);
    const [openInstructionPopUp, setOpenInstructionPopUp] = React.useState(false);
    const dispatch = useDispatch();
    const { mutateAsync: saveCancelRemainder } = useCancelRemainder();
    const { mutateAsync: saveReActivateRemainder } = useReactivateRemainder();
    const invoiceMessages = useSelector(({ invoices }: RootStateStore) => invoices.chat[makeInvoiceChatId({ invoiceNumber: invoiceItem.InvDocNo })]
        ? invoices.chat[makeInvoiceChatId({ invoiceNumber: invoiceItem.InvDocNo })].messages?.sort((a, b) => moment(a.sentAt) > moment(b.sentAt) ? 1 : -1)
        : []);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSendRemainder = () => {
        const lastMessage = invoiceMessages[invoiceMessages.length - 1];
        sendRemainder(dispatch, lastMessage, invoiceItem, setSuccessMessage);
        reFetchBlockedParkedData();
    };

    const handleCancelRemainder = async () => {
        saveCancelRemainder(invoiceItem?.InvDocNo);
        reFetchBlockedParkedData();
        setSuccessMessage("Reminder cancelled successfully");
    }

    const handleReactivateRemainder = async () => {
        saveReActivateRemainder(invoiceItem?.InvDocNo);
        reFetchBlockedParkedData();
        setSuccessMessage("Reminder reactivated successfully");
    }

    const handleDelete = () => {
        setOpen(prevState => !prevState);
    };

    const handleSendInstruction = (instructions: string) => {
        sendInstructions(instructions, invoiceItem);
    };

    useEffect(() => {
        Boolean(anchorEl) && dispatch({
            type: invoiceChatActions.onLoadMessages,
            invoiceNumber: invoiceItem.InvDocNo,
            prNo: invoiceItem.PrNo ? invoiceItem.PrNo : "",
            poNo: invoiceItem.PoNo
        })
    }, [anchorEl]);

    return (
        <div>
            <IconButton onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <MenuItem
                    className={styles.button}
                    onClick={handleDelete}
                    style={{
                        color: "#F19001",
                        cursor: "pointer",
                    }}>
                    <div style={{ marginLeft: "5px" }}>{invoiceItem?.AssignedTo ? "Reassign task" : "Assign Task"}</div>
                </MenuItem>
                <MenuItem
                    className={styles.button}
                    onClick={() => setOpenInstructionPopUp(true)}
                    style={{
                        color: "#F19001",
                        cursor: "pointer",
                    }}>
                    <div style={{ marginLeft: "5px" }}>Send Instructions</div>
                </MenuItem>
                {invoiceItem?.EnableNotification && <MenuItem
                    disabled={!invoiceMessages}
                    className={styles.button}
                    onClick={handleSendRemainder}
                    style={{
                        color: "#F19001",
                        cursor: "pointer",
                    }}>
                    <div style={{ marginLeft: "5px" }}>Send Reminder</div>
                </MenuItem>
                }
                {(invoiceItem?.EnableNotification && invoiceItem?.NotificationCount > 0) && <MenuItem
                    disabled={!invoiceMessages}
                    className={styles.button}
                    onClick={handleCancelRemainder}
                    style={{
                        color: "#F19001",
                        cursor: "pointer",
                    }}>
                    <div style={{ marginLeft: "5px" }}>Deactivate Reminder</div>
                </MenuItem>
                }
                {(!invoiceItem?.EnableNotification && invoiceItem?.NotificationCount > 0) && <MenuItem
                    disabled={!invoiceMessages}
                    className={styles.button}
                    onClick={handleReactivateRemainder}
                    style={{
                        color: "#F19001",
                        cursor: "pointer",
                    }}>
                    <div style={{ marginLeft: "5px" }}>Reactivate Reminder</div>
                </MenuItem>
                }
                {(selectedSubTab === NbsBlockedParkedTabs.PENDING) && <MenuItem
                    className={styles.button}
                    onClick={async () => {
                        window.open(invoiceItem.WfUrl, '_blank');
                        await DocumentFlowService.logUserInteractionData(UserInteractionType.OpenedWorkflowInvoiceIssues);
                    }}
                    style={{
                        color: "#F19001",
                        cursor: "pointer",
                    }}>
                    <div style={{ marginLeft: "5px" }}>Access WF in SAP</div>
                </MenuItem>
                }
            </Menu>
            {
                openInstructionPopUp &&
                <NbsSendInstructionPopUpComponent
                    invoice={invoiceItem}
                    openInstructionPopUp={openInstructionPopUp}
                    setOpenInstructionPopUp={setOpenInstructionPopUp}
                    handleSendInstruction={handleSendInstruction}
                    closeMenu={handleClose}
                    zone={zone}

                />
            }
            {open && <NbsBlockedParkedAssignmentPopUp
                bulkAssign={false}
                setOpen={setOpen}
                assignmentInvoices={[invoiceItem]}
                onSuccessAssignment={onSuccessAssignment}
            />
            }
        </div>
    );
}
