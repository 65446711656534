import * as R from "ramda";
import {DateTime} from "luxon";

export function isLocalEnvironment(): boolean {
  return (process.env.REACT_APP_ENV === "local" || process.env.REACT_APP_ENV === "debug");
}

export const renameKeys = R.curry((keysMap, obj) =>
  R.reduce((acc, key) => R.assoc(keysMap[key] || key, obj[key], acc), {}, R.keys(obj))
);

export const stringOrEmpty = (value) => {
  return value ? value.toString().trim() : "";
}

export const capitalize = (toCap: string): string => {
  return toCap.charAt(0).toUpperCase() + toCap.slice(1);
}

export const abbrNumber = (number) => {
  if (R.either(R.isNil, R.isEmpty)(number)) return number;

  const floatNumber = parseFloat(number);
  const SYMBOLS = ["", "k", "M", "G", "T", "P", "E"];
  const tier = Math.log10(floatNumber) / 3 | 0;
  if(tier == 0) return floatNumber;
  const scale = Math.pow(10, tier * 3);
  const scaled = number / scale;
  return scaled.toFixed(1).replace(/\.0$/, '') + SYMBOLS[tier];
};

export const dateTimeFromFormat = (date, dateFormat = 'yyyyMMdd') => {
  if (date && typeof date === 'string') {
    date = DateTime.fromFormat(date, dateFormat);
  }
  return date;
};

export const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise<any>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};
