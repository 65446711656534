import * as React from "react";
import { CloseIcon } from "../layout/styles";
import { BannerWrapper, BannerTitle, NewBannerWrapper, NewBannerWrapperForMultiEditPr, NewBannerWrapperForNbs, NbsBannerTitle } from "./banner.styles";
import { PrimaryButton } from "../button";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { logUserIntraction } from "src/app/nbsCockpit/helpers/functions";
import { UserInteractionType } from "../../domains/user/userInteractionType";
import { Button, DialogContent, IconButton, Popover, makeStyles } from "@material-ui/core";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStateStore } from "src/app/application.reducers";
import { makeInvoiceChatId } from "src/app/docFlows/reducers/invoiceChat.reducer";
import moment from 'moment';
import { sendRemainder } from "src/app/nbsCockpit/components/nbsBlockedParkedMoreButton";
import { useCancelRemainder, useReactivateRemainder } from "src/app/nbsCockpit/hooks/nbsCockpitCustomHooks";
import { NbsBlockedParkedTabs } from "src/app/nbsCockpit/helpers/constants";
import { DocumentFlowService } from "src/app/docFlows/domains/documentFlow/documentFlow.service";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { NbsSendInstructionPopUpComponent } from "src/app/nbsCockpit/components/nbsSendInstructionPopUp.component";
import { NbsBlockedParkedAssignmentPopUp } from "src/app/nbsCockpit/components/nbsBlockedParkedAssignmentPopUp.component";


interface BannerProps {
  title: string;
  onClose: any;
}

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
  },
  popoverContent: {
    pointerEvents: 'auto',
  }
}));

export function Banner(props: BannerProps) {
  return (
    <BannerWrapper>
      <div style={{ display: "flex", alignItems: "center" }}>
        <CloseIcon onClick={props.onClose}>close</CloseIcon>
        <BannerTitle>{props.title}</BannerTitle>
      </div>
    </BannerWrapper>
  )
}

export function NewBanner(props: BannerProps) {
  return (
    <NewBannerWrapper>
      <div style={{ display: "flex", alignItems: "center" }}>
        <BannerTitle>{props.title}</BannerTitle>
      </div>
      <PrimaryButton
        buttonlabel={"Close"}
        onClick={props.onClose} />
    </NewBannerWrapper>
  )
}

export function NewBannerWrapperForMultiEdit(props) {
  return (
    <NewBannerWrapperForMultiEditPr>
      <div style={{ display: "flex", alignItems: "center" }}>
        <BannerTitle style={props.titleStyle ? props.titleStyle : {}}>{props.title}</BannerTitle>
      </div>
      <CloseIcon onClick={props.onClose} style={{ color: '#5D5858', marginRight: 20 }}>close</CloseIcon>
    </NewBannerWrapperForMultiEditPr>
  )
}

export const NbsViewDetailsButton = ({ tab, zone, sapUrl, requestCockpitUrl, selectedItem, setSuccessMessage, reFetchBlockedParkedData, selectedSubTab, handleSendInstruction }: { tab: string, zone: string, sapUrl: string, requestCockpitUrl: string, selectedItem: any, setSuccessMessage?: React.Dispatch<React.SetStateAction<string>>, reFetchBlockedParkedData?: any, selectedSubTab?: string, handleSendInstruction?: (instructions: string) => void }) => {

  const [anchorEl, setAnchorEl] = React.useState<any | null | boolean>(null);
  const dispatch = useDispatch();
  const { mutateAsync: saveCancelRemainder } = useCancelRemainder();
  const { mutateAsync: saveReActivateRemainder } = useReactivateRemainder();
  const [openInstructionPopUp, setOpenInstructionPopUp] = React.useState(false);
  const popoverAnchor = useRef(null);
  const classes = useStyles({});

  const [open, setOpen] = React.useState(false);

  const isItEasyRequest = tab === "EasyRequest";
  const isItBlockedParked = tab === "BlockedParked";
  const invoiceMessages = useSelector(({ invoices }: RootStateStore) => invoices.chat[makeInvoiceChatId({ invoiceNumber: selectedItem.InvDocNo })]
    ? invoices.chat[makeInvoiceChatId({ invoiceNumber: selectedItem.InvDocNo })].messages?.sort((a, b) => moment(a.sentAt) > moment(b.sentAt) ? 1 : -1)
    : []);

  const handleSendRemainder = () => {
    const lastMessage = invoiceMessages[invoiceMessages.length - 1];
    sendRemainder(dispatch, lastMessage, selectedItem, setSuccessMessage);
    reFetchBlockedParkedData();
  };

  const handleCancelRemainder = async () => {
    saveCancelRemainder(selectedItem?.InvDocNo);
    reFetchBlockedParkedData();
    setSuccessMessage("Reminder cancelled successfully");
  }

  const handleReactivateRemainder = async () => {
    saveReActivateRemainder(selectedItem?.InvDocNo);
    reFetchBlockedParkedData();
    setSuccessMessage("Reminder reactivated successfully");
  }

  const openTaskAssignment = () => {
    setOpen(prevState => !prevState);
  };

  const handleTaskAssignment = () => {
    reFetchBlockedParkedData();
    setSuccessMessage("User successfully assigned");
  };


  return <>
    {!isItBlockedParked && <Button
      size="small"
      ref={popoverAnchor}
      style={{
        color: "#F19001", textTransform: "capitalize",
        padding: "7px",
        fontSize: "13px",
        border: "1px solid #F19001",
        borderRadius: "4px",
        marginRight: "20px"
      }}
      onClick={(event) => setAnchorEl(event.currentTarget)}
      onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
      onMouseLeave={() => setAnchorEl(null)}
    >

      <div style={{ display: 'flex', alignItems: "center" }}>
        <span>View Details</span><ExpandMoreIcon style={{ marginLeft: '14px', fontSize: '22px' }} />
      </div>
    </Button>
    }
    {isItBlockedParked && <Button
      size="small"
      ref={popoverAnchor}
      style={{
        color: "rgba(0, 0, 0, 0.54)",
        cursor: "pointer"
      }}
      onClick={(event) => setAnchorEl(event.currentTarget)}
      onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
      onMouseLeave={() => setAnchorEl(null)}
    >
      <MoreVertIcon />
    </Button>
    }

    <Popover
      open={Boolean(anchorEl)}
      id="mouse-over-popover"
      keepMounted
      className={classes.popover}
      classes={{
        paper: classes.popoverContent,
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      anchorEl={popoverAnchor.current}
      onClose={() => setAnchorEl(null)}
      PaperProps={{ onMouseEnter: () => setAnchorEl(true), onMouseLeave: () => setAnchorEl(null) }}
    >
      <DialogContent style={{ margin: 0, padding: 0 }}>
        <div style={{ display: 'flex', flexDirection: "column" }}>
          {isItEasyRequest && <Button style={{ color: "#F19001", cursor: "pointer", padding: "10px", fontSize: "13px", textTransform: "none" }} onClick={() => {
            logUserIntraction(UserInteractionType.ViewDetailsSAPNBSCockpit)
            window.open(sapUrl, '_blank');
          }}
          >SAP</Button>}
          {isItEasyRequest && <Button style={{ color: "#F19001", cursor: "pointer", padding: "10px", fontSize: "13px", textTransform: "none" }} onClick={() => {
            logUserIntraction(UserInteractionType.ViewDetailsRCNBSCockpit);
            window.open(requestCockpitUrl, '_blank');
          }}
          >Request Cockpit</Button>}
          {isItBlockedParked && <Button
            style={{ color: "#F19001", cursor: "pointer", padding: "10px", fontSize: "13px", textTransform: "none" }}
            onClick={() => setOpenInstructionPopUp(true)}
          >
            Send Instructions
          </Button>
          }
          {isItBlockedParked && <Button
            style={{ color: "#F19001", cursor: "pointer", padding: "10px", fontSize: "13px", textTransform: "none" }}
            onClick={openTaskAssignment}
          >
            <div style={{ marginLeft: "5px" }}>{selectedItem?.AssignedTo ? "Reassign task" : "Assign Task"}</div>
          </Button>
          }
          {isItBlockedParked && selectedItem?.EnableNotification && <Button
            style={{ color: "#F19001", cursor: "pointer", padding: "10px", fontSize: "13px", textTransform: "none" }}
            onClick={handleSendRemainder}
          >
            Send Reminder
          </Button>
          }
          {isItBlockedParked && (selectedItem?.EnableNotification && selectedItem?.NotificationCount > 0) && <Button
            style={{ color: "#F19001", cursor: "pointer", padding: "10px", fontSize: "13px", textTransform: "none" }}
            onClick={handleCancelRemainder}
          > Deactivate Reminder</Button>}
          {isItBlockedParked && (!selectedItem?.EnableNotification && selectedItem?.NotificationCount > 0) && <Button
            style={{ color: "#F19001", cursor: "pointer", padding: "10px", fontSize: "13px", textTransform: "none" }}
            onClick={handleReactivateRemainder}
          > Reactivate Reminder</Button>}
          {isItBlockedParked && (selectedSubTab === NbsBlockedParkedTabs.PENDING) && <Button
            style={{ color: "#F19001", cursor: "pointer", padding: "10px", fontSize: "13px", textTransform: "none" }}
            onClick={async () => {
              window.open(selectedItem?.WfUrl, '_blank');
              await DocumentFlowService.logUserInteractionData(UserInteractionType.OpenedWorkflowInvoiceIssues);
            }}
          > Access WF in SAP</Button>}
        </div>
        {
          openInstructionPopUp &&
          <NbsSendInstructionPopUpComponent
            key={selectedItem}
            invoice={selectedItem}
            openInstructionPopUp={openInstructionPopUp}
            setOpenInstructionPopUp={setOpenInstructionPopUp}
            handleSendInstruction={handleSendInstruction}
            closeMenu={setAnchorEl}
            zone={zone}

          />
        }
        {open && <NbsBlockedParkedAssignmentPopUp
          bulkAssign={false}
          setOpen={setOpen}
          assignmentInvoices={[selectedItem]}
          onSuccessAssignment={handleTaskAssignment}
        />
        }
      </DialogContent>
    </Popover >
  </>
}

export function NewBannerWrapperForNbsCockpit({
  title, tab, zone, selectedItem, onClose, urlData, setSuccessMessage,
  reFetchBlockedParkedData, selectedSubTab, handleSendInstruction }:
  {
    title: string | JSX.Element, tab: string, zone: string, selectedItem: any,
    onClose: React.MouseEventHandler<HTMLSpanElement>, urlData?: { sapUrl: string, requestCockpitUrl: string },
    setSuccessMessage?: React.Dispatch<React.SetStateAction<string>>, reFetchBlockedParkedData?: any,
    selectedSubTab?: string, handleSendInstruction?: (instructions: string) => void
  }) {
  return (
    <NewBannerWrapperForNbs>
      <div style={{ display: "flex", alignItems: "center" }}>
        <NbsBannerTitle>{title}</NbsBannerTitle>
      </div>
      <div style={{ display: 'flex', alignItems: "center" }}>
        {<NbsViewDetailsButton
          tab={tab}
          zone={zone}
          sapUrl={urlData?.sapUrl}
          requestCockpitUrl={urlData?.requestCockpitUrl}
          selectedItem={selectedItem}
          setSuccessMessage={setSuccessMessage}
          reFetchBlockedParkedData={reFetchBlockedParkedData}
          selectedSubTab={selectedSubTab}
          handleSendInstruction={handleSendInstruction}
        />
        }
        <CloseIcon onClick={onClose} style={{ color: '#5D5858' }}>close</CloseIcon>
      </div>
    </NewBannerWrapperForNbs>
  )
}