import * as R from "ramda";
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { QueryActions } from '../reducers/queryableDocumentFlowReducer';
import { IDocflowFilters } from "../../shared/domains/user/user";
import { CreationDateFilter, DateRangeFilter, TextFilter } from "../../shared/domains/query/filter";
import { UserService } from "../../shared/domains/user/user.service";
import { SaveDocflowFiltersError, SaveDocflowFiltersSuccess } from "../../shared/actions/user.actions";


export function convertToDocFlowFilters(filters, docType): IDocflowFilters {
  const delDate: DateRangeFilter = R.path(['DelDate', 'selected'], filters);
  const companies: TextFilter[] = R.path(['POCompanyCode', 'selected'], filters);
  const pOrgs: TextFilter[] = R.path(['PurchOrg', 'selected'], filters);
  const pGroups: TextFilter[] = R.path(['PurchGroup', 'selected'], filters);
  const plants: TextFilter[] = R.path(['PRPlant', 'selected'], filters);
  const creationDate: CreationDateFilter = R.path(['CreationDate', 'selected'], filters);

  const poRequestor: TextFilter[] = R.path(['PoCreatorName', 'selected'], filters);
  const prRequestor: TextFilter[] = R.path(['PrCreatorName', 'selected'], filters);

  const vendorNo: TextFilter[] = R.path(['VendorNo', 'selected'], filters)
  const costCenter: TextFilter[] = R.path(['CostCenter', 'selected'], filters)
  const projectId: TextFilter[] = R.path(['WbsElement', 'selected'], filters)
  const requester: TextFilter[] = R.path(['Requisitioner', 'selected'], filters)
  const complianceIndicator: TextFilter[] = R.path(['CollectiveNumber', 'selected'], filters)

  const status: TextFilter[] = R.path(['Status', 'selected'], filters)
  const priceRangeFrom: TextFilter[] = R.path(['PriceRange', 'selected', 'priceFrom'], filters)
  const priceRangeTo: TextFilter[] = R.path(['PriceRange', 'selected', 'priceTo'], filters)
  const currency: TextFilter[] = R.path(['Currency', 'selected'], filters)
  const material: TextFilter[] = R.path(['MaterialNo', 'selected'], filters)
  const poNumber: TextFilter[] = R.path(['PurchaseOrdNo', 'selected'], filters)
  const prNumber: TextFilter[] = R.path(['PurchaseReqNo', 'selected'], filters)
  const prPoType: TextFilter[] = R.path(['DocType', 'selected'], filters)

  const extContactNumber: TextFilter[] = R.path(['ExtContrNo', 'selected'], filters)
  const ppmCostPlan: TextFilter[] = R.path(['PPMCstPln', 'selected'], filters)
  const sourcingID: TextFilter[] = R.path(['Sourcing_ID', 'selected'], filters)
  const extReqNumber: TextFilter[] = R.path(['ExtReqNo', 'selected'], filters)
  const pslID: TextFilter[] = R.path(['Psl_ID', 'selected'], filters)

  return {
    companies,
    pOrgs,
    pGroups,
    plants,
    poRequestor,
    prRequestor,
    vendorNo,
    costCenter,
    projectId,
    requester,
    complianceIndicator,
    creationDate: {
      type: creationDate?.type ? creationDate?.type : "PO",
      buttonName: creationDate?.buttonName ? creationDate?.buttonName : null,
      startDate: creationDate?.startDate ? creationDate?.startDate.includes("Invalid") ? null : creationDate?.startDate : null,
      endDate: creationDate?.endDate ? creationDate?.endDate.includes("Invalid") ? null : creationDate?.endDate : null
    },
    status,
    priceRangeFrom,
    priceRangeTo,
    priceRange: null,
    currency,
    material,
    poNumber,
    prNumber,
    docType: prPoType,
    deliveryDateRange: (delDate.startDate && delDate.endDate) ?
      {
        startDate: moment.utc(delDate.startDate, 'YYYYMMDD'),
        endDate: moment.utc(delDate.endDate, 'YYYYMMDD')
      } : null,
      extContactNumber,
      ppmCostPlan,
      sourcingID,
      extReqNumber,
      pslID,
  };
}

function* saveFilters(action) {

  try {
    const docType = action?.list?.docFlowType;

    const delDate: DateRangeFilter = R.path(['DelDate', 'selected'], action.filters);

    let filters: IDocflowFilters = action.skipMapping ? action.filters : convertToDocFlowFilters(action.filters, docType);
    yield call(UserService.saveDocflowFilters, filters, docType);
    yield put(new SaveDocflowFiltersSuccess(R.mergeRight(filters, {
      deliveryDateRange: delDate
    }), docType));
  } catch (error) {
    yield put(new SaveDocflowFiltersError(error));
  }
}

function* watchSaveFilters() {
  yield takeLatest(QueryActions.onSaveFilters, saveFilters);
}

export default function* dfFilterSaga() {
  yield all([
    fork(watchSaveFilters)
  ])
};
