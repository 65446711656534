import {IUserRole} from "./user";
import {IColumnsList} from "./columns.hook";
import {arraysConcat} from "../../utils/arraysHelper";

export enum MaterialColumns {
  SUPPLIER_CONTACT = "supplier_contact",
  SUPPLIER_STATUS = "supplier_status",
  VALID_FROM = "valid_from",
  VALID_TO = "valid_to",
  ZONE = "zone",
  MARKET = "market",
  COMPANY_CODE = "company_code",
  AGREEMENTS = "agreements",
  BUYER_NAME = "buyer_name",
  TEAM_NAME = "team_name",
  NOTE_REQUESTER = "note_requester",
  NOTE_LOCAL_PROCUREMENT = "note_local_procurement",
  TENDER_NUMBER = "tender_number",
  BUSINESS_UNIT = "business_unit",
  PRICE_LIST_LINK = "price_list_link",
  PRICE_AVAILABLE = "price_available",
  PSL_ID = "psl_id",
  PARENT_VENDOR = "parent_vendor",
  CHILD_VENDOR = "child_vendor",
  LOCAL_CHILD_VENDOR = "local_child_vendor",
  KEY_WORDS = "key_words",
  SHORT_DESCRIPTION = "short_description",
  ITEM_DESCRIPTION = "item_description",
  UNIT_OF_MEASURE = "unit_of_measure",
  UNIT_PRICE = "unit_price",
  PRICE_PER = "price_per",
  CURRENCY = "currency",
  MATERIAL_NUMBER = "material_number",
  SPEND_CATEGORY_L1 = "spend_category_l1",
  SPEND_CATEGORY_L2 = "spend_category_l2",
  SPEND_CATEGORY_L3 = "spend_category_l3",
  LANGUAGE = "language",
  IMAGE = "image",
  THUMBNAIL = "thumbnail",
  REPLICATION_STATUS = "replication_status",
  PART_ID = "part_id",
  SUPPLIER_LEAD_TIME = "supplier_lead_time",
  CREATED_BY_USER = "created_by_user",
  UPDATED_BY_USER = "updated_by_user",
  CREATED_ON = "created_on",
  UPDATED_ON = "updated_on",
  RELATED_TO_EASYBUY = "related_to_easybuy",
  STATUS_ATTACHMENTS = "status_attachments",
  STATUS_JUSTIFICATION = "status_justification",
  MANUFACTURER_PART_NUMBER = "manufacturer_part_number",
  MANUFACTURER_NAME = "manufacturer_name",
  FORM_STATUS = "form_status",
  SCOPE = "scope",
  ADD_TO_EASY_REQUEST = "add_easy_request",
  APPROVERS = "approvers",
  Supplier_Diversity = "Supplier Diversity",
  Market_Contact_Name = "Market Contact Name",
  Way_Of_Buying = "Way Of Buying",
  PLANT = "plant",
  Localizer = "Localizer",
  REFRENCE_NUMBER = "Ref No",
  MATERIAL_ID = "material_id"
}
export interface MaterialColumnsList extends IColumnsList {
  id: MaterialColumns,
}

export function GetDefaultMaterialsColumns(userRole: IUserRole) {
  switch (userRole) {
    case IUserRole.REQUESTOR:
    case IUserRole.FACTORY_ANALYST:
      return MaterialAsRequestor();
    case IUserRole.AT_MARKET:
    case IUserRole.ABOVE_MARKET:
    case IUserRole.PPA_ABOVE_MARKET:
    case IUserRole.PPA_AT_MARKET:
    case IUserRole.OPERATIONAL_BUYER:
      return MaterialAsAtMarket();
    default:
      return MaterialAsAuxPanel();
  }
}

export function GetMaterialColumns(userRole: IUserRole, columns: MaterialColumnsList[]): MaterialColumnsList[] {
  return arraysConcat(columns, GetDefaultMaterialsColumns(userRole));
}

function MaterialAsRequestor() {
  return [
    {id: MaterialColumns.PSL_ID, show: false, hidden: false},
    {id: MaterialColumns.MATERIAL_ID, show: true, hidden: false},
    {id: MaterialColumns.SPEND_CATEGORY_L1, show: false, hidden: false},
    {id: MaterialColumns.SPEND_CATEGORY_L2, show: false, hidden: false},
    {id: MaterialColumns.SPEND_CATEGORY_L3, show: true, hidden: false},
    {id: MaterialColumns.MATERIAL_NUMBER, show: true, hidden: false},
    {id: MaterialColumns.ITEM_DESCRIPTION, show: true, hidden: false},
    {id: MaterialColumns.PRICE_AVAILABLE, show: true, hidden: false},
    {id: MaterialColumns.NOTE_LOCAL_PROCUREMENT, show: true, hidden: true},
    {id: MaterialColumns.SHORT_DESCRIPTION, show: false, hidden: false},
    {id: MaterialColumns.PARENT_VENDOR, show: true, hidden: true},
    {id: MaterialColumns.LOCAL_CHILD_VENDOR, show: true, hidden: false},
    {id: MaterialColumns.SUPPLIER_STATUS, show: false, hidden: false},
    {id: MaterialColumns.SUPPLIER_LEAD_TIME, show: true, hidden: false},
    {id: MaterialColumns.NOTE_REQUESTER, show: true, hidden: false},
    {id: MaterialColumns.BUYER_NAME, show: false, hidden: false},
    {id: MaterialColumns.TEAM_NAME, show: false, hidden: false},
    {id: MaterialColumns.ZONE, show: false, hidden: false},
    {id: MaterialColumns.MARKET, show: false, hidden: false},
    {id: MaterialColumns.COMPANY_CODE, show: false, hidden: false},
    {id: MaterialColumns.BUSINESS_UNIT, show: false, hidden: false},
    {id: MaterialColumns.SUPPLIER_CONTACT, show: false, hidden: false},
    {id: MaterialColumns.VALID_FROM, show: false, hidden: false},
    {id: MaterialColumns.VALID_TO, show: false, hidden: false},
    {id: MaterialColumns.AGREEMENTS, show: false, hidden: false},
    {id: MaterialColumns.TENDER_NUMBER, show: false, hidden: false},
    {id: MaterialColumns.PRICE_LIST_LINK, show: false, hidden: false},
    {id: MaterialColumns.KEY_WORDS, show: false, hidden: false},
    {id: MaterialColumns.UNIT_OF_MEASURE, show: false, hidden: false},
    {id: MaterialColumns.UNIT_PRICE, show: false, hidden: false},
    {id: MaterialColumns.PRICE_PER, show: false, hidden: false},
    {id: MaterialColumns.CURRENCY, show: false, hidden: false},
    {id: MaterialColumns.LANGUAGE, show: false, hidden: false},
    {id: MaterialColumns.PART_ID, show: false, hidden: false},
    {id: MaterialColumns.REPLICATION_STATUS, show: false, hidden: true},
    {id: MaterialColumns.CREATED_BY_USER, show: false, hidden: false},
    {id: MaterialColumns.CREATED_ON, show: false, hidden: false},
    {id: MaterialColumns.UPDATED_BY_USER, show: false, hidden: false},
    {id: MaterialColumns.UPDATED_ON, show: false, hidden: false},
    {id: MaterialColumns.RELATED_TO_EASYBUY, show: false, hidden: false},
    {id: MaterialColumns.MANUFACTURER_PART_NUMBER, show: false, hidden: false},
    {id: MaterialColumns.MANUFACTURER_NAME, show: false, hidden: false},
    {id: MaterialColumns.STATUS_JUSTIFICATION, show: false, hidden: true},
    {id: MaterialColumns.STATUS_ATTACHMENTS, show: false, hidden: true},
    {id: MaterialColumns.FORM_STATUS, show: false, hidden: false},
    {id: MaterialColumns.SCOPE, show: false, hidden: false},
    {id: MaterialColumns.ADD_TO_EASY_REQUEST, show: true, hidden: false},
    {id: MaterialColumns.APPROVERS, show: false, hidden: false},
    {id: MaterialColumns.Supplier_Diversity, show: false, hidden: false},
    {id: MaterialColumns.Market_Contact_Name, show: false, hidden: false},
    {id: MaterialColumns.Way_Of_Buying, show: false, hidden: false},
    {id: MaterialColumns.PLANT, show: true, hidden: false},
    {id: MaterialColumns.Localizer, show: false, hidden: false},
    {id: MaterialColumns.REFRENCE_NUMBER, show: true, hidden: false},
  ];
}

function MaterialAsAtMarket() {
  return [
    {id: MaterialColumns.PSL_ID, show: true, hidden: false},
    {id: MaterialColumns.MATERIAL_ID, show: true, hidden: false},
    {id: MaterialColumns.SPEND_CATEGORY_L1, show: false, hidden: false},
    {id: MaterialColumns.SPEND_CATEGORY_L2, show: false, hidden: false},
    {id: MaterialColumns.SPEND_CATEGORY_L3, show: true, hidden: false},
    {id: MaterialColumns.MATERIAL_NUMBER, show: true, hidden: false},
    {id: MaterialColumns.ITEM_DESCRIPTION, show: true, hidden: false},
    {id: MaterialColumns.PRICE_AVAILABLE, show: true, hidden: false},
    {id: MaterialColumns.NOTE_LOCAL_PROCUREMENT, show: true, hidden: false},
    {id: MaterialColumns.SHORT_DESCRIPTION, show: false, hidden: false},
    {id: MaterialColumns.PARENT_VENDOR, show: true, hidden: false},
    {id: MaterialColumns.LOCAL_CHILD_VENDOR, show: true, hidden: false},
    {id: MaterialColumns.SUPPLIER_STATUS, show: false, hidden: false},
    {id: MaterialColumns.SUPPLIER_LEAD_TIME, show: false, hidden: false},
    {id: MaterialColumns.NOTE_REQUESTER, show: true, hidden: false},
    {id: MaterialColumns.BUYER_NAME, show: false, hidden: false},
    {id: MaterialColumns.TEAM_NAME, show: false, hidden: false},
    {id: MaterialColumns.ZONE, show: false, hidden: false},
    {id: MaterialColumns.MARKET, show: false, hidden: false},
    {id: MaterialColumns.COMPANY_CODE, show: false, hidden: false},
    {id: MaterialColumns.BUSINESS_UNIT, show: false, hidden: false},
    {id: MaterialColumns.SUPPLIER_CONTACT, show: false, hidden: false},
    {id: MaterialColumns.VALID_FROM, show: false, hidden: false},
    {id: MaterialColumns.VALID_TO, show: false, hidden: false},
    {id: MaterialColumns.AGREEMENTS, show: false, hidden: false},
    {id: MaterialColumns.TENDER_NUMBER, show: false, hidden: false},
    {id: MaterialColumns.PRICE_LIST_LINK, show: false, hidden: false},
    {id: MaterialColumns.KEY_WORDS, show: false, hidden: false},
    {id: MaterialColumns.UNIT_OF_MEASURE, show: false, hidden: false},
    {id: MaterialColumns.UNIT_PRICE, show: false, hidden: false},
    {id: MaterialColumns.PRICE_PER, show: false, hidden: false},
    {id: MaterialColumns.CURRENCY, show: false, hidden: false},
    {id: MaterialColumns.LANGUAGE, show: false, hidden: false},
    {id: MaterialColumns.PART_ID, show: false, hidden: false},
    {id: MaterialColumns.REPLICATION_STATUS, show: false, hidden: false},
    {id: MaterialColumns.CREATED_BY_USER, show: false, hidden: false},
    {id: MaterialColumns.CREATED_ON, show: false, hidden: false},
    {id: MaterialColumns.UPDATED_BY_USER, show: false, hidden: false},
    {id: MaterialColumns.UPDATED_ON, show: false, hidden: false},
    {id: MaterialColumns.RELATED_TO_EASYBUY, show: false, hidden: false},
    {id: MaterialColumns.MANUFACTURER_PART_NUMBER, show: false, hidden: false},
    {id: MaterialColumns.MANUFACTURER_NAME, show: false, hidden: false},
    {id: MaterialColumns.STATUS_JUSTIFICATION, show: false, hidden: false},
    {id: MaterialColumns.STATUS_ATTACHMENTS, show: false, hidden: false},
    {id: MaterialColumns.FORM_STATUS, show: false, hidden: false},
    {id: MaterialColumns.SCOPE, show: false, hidden: false},
    {id: MaterialColumns.ADD_TO_EASY_REQUEST, show: true, hidden: false},
    {id: MaterialColumns.APPROVERS, show: false, hidden: false},
    {id: MaterialColumns.Supplier_Diversity, show: false, hidden: false},
    {id: MaterialColumns.Market_Contact_Name, show: false, hidden: false},
    {id: MaterialColumns.Way_Of_Buying, show: false, hidden: false},
    {id: MaterialColumns.PLANT, show: true, hidden: false},
    {id: MaterialColumns.Localizer, show: false, hidden: false},
    {id: MaterialColumns.REFRENCE_NUMBER, show: true, hidden: false},
  ];
}

function MaterialAsAuxPanel() {
  return [
    {id: MaterialColumns.PSL_ID, show: false, hidden: false},
    {id: MaterialColumns.MATERIAL_ID, show: true, hidden: false},
    {id: MaterialColumns.SPEND_CATEGORY_L1, show: false, hidden: false},
    {id: MaterialColumns.SPEND_CATEGORY_L2, show: false, hidden: false},
    {id: MaterialColumns.SPEND_CATEGORY_L3, show: true, hidden: false},
    {id: MaterialColumns.PLANT, show: true, hidden: false},
    {id: MaterialColumns.MATERIAL_NUMBER, show: true, hidden: false},
    {id: MaterialColumns.ITEM_DESCRIPTION, show: true, hidden: false},
    {id: MaterialColumns.SUPPLIER_LEAD_TIME, show: true, hidden: false},
    {id: MaterialColumns.UNIT_PRICE, show: true, hidden: false},
    {id: MaterialColumns.CURRENCY, show: true, hidden: false},
    {id: MaterialColumns.PRICE_PER, show: true, hidden: false},
    {id: MaterialColumns.UNIT_OF_MEASURE, show: true, hidden: false},
    {id: MaterialColumns.Supplier_Diversity, show: false, hidden: false},
    {id: MaterialColumns.Market_Contact_Name, show: false, hidden: false},
    {id: MaterialColumns.Localizer, show: false, hidden: false},
    {id: MaterialColumns.REFRENCE_NUMBER, show: true, hidden: false},
  ];
}