import React from 'react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { CloseIcon, StyledDialogTitle } from 'src/app/shared/components/layout/styles';
import { Invoice } from 'src/app/docFlows/invoices/domains/invoices/Invoice';
import { StyledConfirmDialog } from 'src/app/shared/containers/dashboard/components/feedback/feedback.styles';
import { StyledDiv } from 'src/app/admin/components/confirmPopupModel/confirmpopup.styles';
import { LoadingComponent } from 'src/app/shared/components/loading';
import { PSLService } from 'src/app/preferredSuppliers/domains/psl.service';
import { CustomFastAsyncSelect } from 'src/app/shared/components/form';
import { useSelector } from 'react-redux';
import { RootStateStore } from 'src/app/application.reducers';
import { SelectContainer } from 'src/app/shared/components/filters/filters.styles';
import { NbsNotification } from './nbsNotification.component';
import { NotificationType } from 'src/app/shared/components/notification';
import { useAssignBlockedParkedData } from '../hooks/nbsCockpitCustomHooks';

export interface assignmentData {
    invoiceNumber: string;
    assignedTo: string;
}

export const nbsFlyOutStyles = makeStyles(theme => ({
    rcTabLabelStyle: {
        fontSize: "0.8rem",
        fontWeight: "bold",
        marginBottom: "18px",
        letterSpacing: "0.01071em"
    },
    rcTabTextStyle: {
        fontSize: "0.8rem",
        fontWeight: "bold",
        letterSpacing: "0.01071em",
        color: "rgb(93 88 93)"
    },
    tableColTextStyle: {
        fontSize: "0.875rem",
        textAlign: "left",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontWeight: 400,
        lineHeight: "1.43",
        letterSpacing: "0.01071em"
    },
}));

export const NbsBlockedParkedAssignmentPopUp = ({ bulkAssign, setOpen, assignmentInvoices, onSuccessAssignment }: { bulkAssign: boolean, setOpen: any, assignmentInvoices: Invoice[], onSuccessAssignment: any }) => {

    const currentUser = useSelector((state: RootStateStore) => state.shared.user.currentUser);
    // const [assignedUser, setAssignedUser] = React.useState(assignmentInvoices[0]?.AssignedTo ? assignmentInvoices[0]?.AssignedTo : currentUser.email);
    const [assignedUser, setAssignedUser] = React.useState(currentUser.email);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const classes = nbsFlyOutStyles({});

    const onClose = () => {
        setOpen(false);
    }

    const onFinished = () => {
        onClose();
        onSuccessAssignment();
    };

    const { mutateAsync: assignBlockedParkedData, isLoading: isAssignmentLoading } = useAssignBlockedParkedData(onFinished);

    const handleSave = async (e: any) => {
        try {
            e.preventDefault();
            const userAssignmentData = assignmentInvoices.map((inVoice: Invoice) => {
                return {
                    invoiceNumber: inVoice?.InvDocNo,
                    assignedTo: assignedUser
                }
            });
            await assignBlockedParkedData(userAssignmentData);
        } catch (error) {
            setErrorMessage(error);
        }
    };



    return (
        <StyledConfirmDialog open={true} maxWidth={"sm"} fullWidth={true}>

            <StyledDialogTitle style={{ backgroundColor: "orange" }}>
                <div style={{ display: "flex", justifyContent: "space-between", color: 'white' }}>
                    <div >{bulkAssign ? "Bulk Assign Tasks" : "Assign Task"}</div>
                    <CloseIcon onClick={onClose}>close</CloseIcon>
                </div>
            </StyledDialogTitle>
            {isAssignmentLoading ? <div style={{ height: "276px" }}><LoadingComponent /></div> : <div style={{ overflow: "hidden" }}>
                {
                    <Grid container spacing={3} style={{ margin: 0, padding: "40px" }}>
                        {!bulkAssign && <Grid item sm={6}>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '2px' }}>
                                <div className={classes.rcTabLabelStyle}>Nestle Invoice</div>
                            </div>
                            <div className={classes.tableColTextStyle}>
                                {assignmentInvoices[0].InvDocNo?.replace(/^0+/, '')}
                            </div>
                        </Grid>
                        }
                        {
                            bulkAssign && <Grid item sm={6}>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: '2px' }}>
                                    <div className={classes.rcTabLabelStyle}>Number of Records to be Assigned</div>
                                </div>
                                <div className={classes.tableColTextStyle}>
                                    {assignmentInvoices.length}
                                </div>
                            </Grid>
                        }
                        <Grid item sm={6}>
                            <div className={classes.rcTabLabelStyle}>Assign To</div>
                            <div className={classes.tableColTextStyle} style={{ display: "inline" }}>
                                <SelectContainer>
                                    <CustomFastAsyncSelect
                                        clearable={false}
                                        onLoad={PSLService.fetchPslUserEmails}
                                        onLoadLimit={4}
                                        initialValue={{
                                            value: assignedUser,
                                            label: assignedUser
                                        }}
                                        onChange={v => setAssignedUser(v?.value)}
                                    />
                                </SelectContainer>
                            </div>
                        </Grid>
                    </Grid>
                }
                {
                    <StyledDiv style={{ display: 'flex', padding: '25px 0px 30px 0px', justifyContent: 'center', gap: '15px' }}>
                        <Button color="primary" onClick={onClose}>CANCEL</Button>
                        <Button color="primary" onClick={handleSave} type="submit">APPLY</Button>
                    </StyledDiv>
                }
            </div>
            }
            {
                errorMessage &&
                <NbsNotification
                    type={NotificationType.error}
                    message={errorMessage?.toString()}
                    open={!!errorMessage}
                    setOpen={setErrorMessage}
                />
            }
        </StyledConfirmDialog>
    );
};
